import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const FlaggedItemsCountContext = createContext();

export const useFlaggedItemsCount = () => useContext(FlaggedItemsCountContext);

export const FlaggedItemsCountProvider = ({ children }) => {
  const [totalFlaggedItems, setTotalFlaggedItems] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/v1/flagitems`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          
          setTotalFlaggedItems(response.data.results);
        })
        .catch((error) => {
          console.error("Error fetching flagged items count:", error);
        });
    }
  }, []);

  return (
    <FlaggedItemsCountContext.Provider value={{ totalFlaggedItems }}>
      {children}
    </FlaggedItemsCountContext.Provider>
  );
};
