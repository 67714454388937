import React from "react";
import "./NotFound.scss";


const NotFound = () => {

  const supportClick = () => {
    window.location.href="/support";
  }
  return (
    <div className="container">
      <div className="wrapper-all-password-requested">
        <div className="first-half-requested">
          <div className="title-password-request">
          <p style={{fontSize: "32px"}}>404</p>
          </div>

          <div className="title-body-password-request">
            <p>Page Not Found</p>
          </div>
        </div>
        <div className="text-center mt- bottom-margin font-weight">
          <p className="text-support" onClick={supportClick}>Request Support</p>
          {/* <Link to="/password-reset-1" className="btn  responsive-button">
            Continue
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
