import React from "react";
import "./SystemSetting.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
// import SearchField from "../../components/search/SearchField";

const SystemSetting = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar header="System Settings" />
          </div>

          {/* <SearchField /> */}

          {/* <h1>Setting</h1> */}
        </div>
      </div>
    </div>
  );
};

export default SystemSetting;
