import React, { useState, useEffect } from "react";
import "./SingleAdminUser.scss";
import { useLocation } from "react-router-dom";
import { Button, OutlinedInput, Box, Typography, FormControl, Input, InputLabel } from '@mui/material';
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TopBar from "../../components/topbar/topbar";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SingleAdminUser = () => {
  const dummyRows = [
    {
      firstName: "John Doe",
      email: "Admin",
      __v: 123,
      date: "2023-08-17",
    },
    {
      firstName: "Jane Smith",
      email: "User",
      __v: 456,
      date: "2023-08-16",
    },
  ];
  const [editFields, setEditFields] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [data, setData] = useState([]);
  const location = useLocation();

  console.log(data);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData)
      const dateObject = new Date(userData.lastActive);
      const formattedDate = dateObject.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
      });

      setFormData({
        firstname: userData?.firstName,
        lastname: userData?.lastName,
        email: userData?.email,
        phone: userData?.phone,
        status: userData?.userStatus,
        userType: userData?.userType,
        lastActive: formattedDate,
      });
    }
    setData(dummyRows);
  }, [location.state]);

  // const handleUserTypeChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     userType: event.target.value,
  //   });
  // };


  const CustomIcon = () => {
    return <ArrowDropDownIcon style={{marginRight: "10px"}}/>;  // Replace with your desired icon component
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
    userType: "",
    lastActive: "",
  });
  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    status: false,
    userType: false,
    lastActive: false,
  });

  console.log(editableFields);

  const handleEditClick = (event) => {
    setEditFields(!editFields);
    if (editFields) {
      event.preventDefault();
      const userId = location.state.userData._id;

      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
          {
            firstName: formData?.firstname,
            lastName: formData?.lastname,
            email: formData?.email,
            phone: formData?.phone,
            userStatus: formData?.status,
            userType: formData?.userType,
            lastActive: formData?.lastActive,
          },
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          console.log("User information updated:", response.data);
          setEditableFields({
            name: false,
            email: false,
            phone: false,
            status: false,
            userType: false,
            lastActive: false,
          });
        })
        .catch((error) => {
          console.error("Error updating admin information:", error);
        });
    }
  }
  const myVariant = editFields ? undefined : "standard";
  const inputLabelStyle = {
    '&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      color: 'black'
    }
  }
  const inputStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  }
  const formStyles = { marginTop: 2 }


  useEffect(() => {
    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      const { fullName = '' } = userData;
      console.log(fullName)
    }
  }, [location.state]);

  // const [selectedUserType, setSelectedUserType] = useState(formData?.userType);
  const [selectedUserStatus, setSelectedUserStatus] = useState(formData?.status);
  console.log(selectedUserStatus);

  return (
    <>
      <div className="home-container">
        <Navbar />
        <div className="content-container">
          <Sidebar />
          <div className="main-card">
            <div className="topbar-container">
              <TopBar
                leftIcon={
                  <Link to="/admin-users" className="links">
                    <ArrowBackIcon className="backIconStyling"/>
                  </Link>
                }
                header={`Admin Users / ${formData?.firstname}`}
              />
            </div>
            <div className="card-wrapper">
              <Container fluid>
                <Box className='card-header'>
                  <Typography className="card-header-about">
                    About
                  </Typography>
                  <Button
                    variant={editFields ? 'contained' : 'outlined'}
                    className={editFields ? 'save-button' : 'edit-button'}
                    onClick={handleEditClick}>
                    {editFields ? 'Save' : 'Edit'}
                  </Button>
                </Box>
                <Box className='form' display='flex' flexDirection='column' pt='24px'>
                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      First Name
                    </InputLabel>
                    {
                      !editFields && <Input
                        sx={inputStyles}
                        value={formData?.firstname}
                        disableUnderline
                      />
                    }
                    {
                      editFields && <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.firstname}
                        label="First Name"
                        onChange={(e) => {

                          setFormData({
                            ...formData,
                            firstname: e.target.value,
                          });
                        }}
                      />
                    }
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}

                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Last Name
                    </InputLabel>
                    {
                      !editFields && <Input
                        sx={inputStyles}
                        value={formData?.lastname}
                        disableUnderline
                      />
                    }
                    {
                      editFields && <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.lastname}
                        label="Username"
                        onChange={(e) => {

                          setFormData({
                            ...formData,
                            lastname: e.target.value,
                          });
                        }}
                      />
                    }
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}

                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Email
                    </InputLabel>
                    {
                      !editFields && <Input
                        sx={inputStyles}
                        value={formData?.email}
                        disableUnderline
                      />
                    }
                    {
                      editFields && <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.email}
                        label="Email"
                        onChange={(e) => {

                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    }
                  </FormControl>
                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Phone
                    </InputLabel>
                    {
                      !editFields && <Input
                        sx={inputStyles}
                        value={formData?.phone}
                        disableUnderline
                      />
                    }
                    {
                      editFields && <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.phone}
                        inputProps={{ maxLength: 10 }}
                        label="Phone"
                        onChange={(e) => {

                          setFormData({
                            ...formData,
                            phone: e.target.value,
                          });
                        }}
                      />
                    }
                  </FormControl>

                  <FormControl variant={myVariant} disabled={!editFields} sx={formStyles}>
                    <InputLabel htmlFor="component-outlined" sx={inputLabelStyle}>
                      Status
                    </InputLabel>
                    {!editFields ? (
                      <Input sx={inputStyles} value={formData?.status} disableUnderline />
                    ) : (
                      <Select
                        id="component-outlined"
                        value={formData?.status}
                        onChange={(e) => {
                          setSelectedUserStatus(e.target.value);
                          setFormData({
                            ...formData,
                            status: e.target.value,
                          });
                        }}
                        label="Status"
                        IconComponent={CustomIcon}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">Inactive</MenuItem>
                      </Select>
                    )}
                  </FormControl>

                </Box>
              </Container>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default SingleAdminUser;