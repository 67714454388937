import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./navbar.scss";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import UpdatePassword from "../../pages/UpdatePassword/UpdatePassword";
import { useNavigate } from "react-router-dom";

export default function PrimarySearchAppBar() {
  const navigate = useNavigate();
  const userData = localStorage.getItem("userInfo");
  const finaluserData = JSON.parse(userData);
  const firstname = finaluserData?.firstName || "";
  const fname = firstname[0].toUpperCase();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showUpdatePassword, setUpdatePassword] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUpdatePassword = () => {
    navigate("/update-password");
    setUpdatePassword(true);
  };
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("selectedTab");
    window.location.replace("/sign-in");
    window.location.reload();
    window.history.replaceState(null, "", "/sign-in");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="navbar custom-navbar" style={{ position: "fixed" }}>
      <div className="navbar-custom-brand">
        <div className="left-nav">
          <div className="navbar-text-uon" style={{ cursor: "default" }}>
            UON
          </div>
        </div>
      </div>

      <div className="right-nav">
        <p className="nav-custom-link">
          <Avatar
            className="nav-custom-link"
            onClick={handleClick}
            style={{
              background: "#D7E2FD",
              color: "#0159C7",
              cursor: "pointer",
            }}
          >
            {fname}
          </Avatar>
        </p>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            {finaluserData?.firstName} {finaluserData?.lastName}
          </MenuItem>
          <MenuItem onClick={handleClose}>{finaluserData.email}</MenuItem>
          <MenuItem onClick={handleUpdatePassword}>Update Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      {showUpdatePassword && (
        <UpdatePassword setUpdatePassword={setUpdatePassword} />
      )}
    </div>
  );
}
