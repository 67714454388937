import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UpdatePassword.scss";
// import { Error as ErrorIcon } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";

const UpdatePassword = () => {
  const [authToken, setAuthToken] = useState("");
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const handleUpdatePassword = () => {
    if (authToken) {
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users/update_password`,
          {
            passwordCurrent: passwordCurrent,
            passwordNew: passwordNew,
            userType: "Admin",
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          console.log("Password updated successfully:", response.data);
          window.location.href = "/admin-users";
        })

        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            setError(error.response.data.message);
          } else {
            setError(error.response.data.message);
          }
        });
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordNewVisibility = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  return (
    <div className="blur-background">
      <div className="wrapper-card-update-password">
        <div className="header-card-update-password">
          <span className="header-text-update-password">
            Update Admin Password
          </span>
          <InfoIcon className="icon-update-password" />
        </div>
        <div
          className="card-field-update-password"
          style={{ marginTop: "50px" }}
        >
          <div className="password-field">
            <input
              type={showPassword ? "text" : "password"}
              className="custom-input-add form-control"
              placeholder="Current Password"
              onChange={(e) => setPasswordCurrent(e.target.value)}
            />
            <div
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </div>
        </div>
        <div className="password-field">
          <input
            type={showPasswordNew ? "text" : "password"}
            className="custom-input-add form-control"
            placeholder="New Password"
            onChange={(e) => setPasswordNew(e.target.value)}
          />
          {
            <div
              className="password-toggle-icon"
              onClick={togglePasswordNewVisibility}
            >
              {showPasswordNew ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          }
        </div>

        {error && <p className="error-message">{error}</p>}
        <div className="card-button-container-update-password">
          <Link to="/admin-users" className="links">
            <button
              className="custom-button-back-update-password"
              
            >
              Cancel
            </button>
          </Link>
          <button
            className="custom-button-create-update-password"
            onClick={handleUpdatePassword}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
