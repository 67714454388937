import React from "react";
import "./passwordResetRequest.scss";
import { useNavigate } from "react-router-dom";

const PasswordResetRequest = () => {
  const navigate = useNavigate();

  const supportClick = () => {
    navigate("/support");
  }
  return (
    <div className="container">
      <div className="wrapper-all-password-requested">
        <div className="first-half-requested">
          <div className="title-password-request">
          <img src="IMG-9805.JPG" alt="logo" style={{width: "64px"}}/>
          </div>

          <div className="title-body-password-request">
            <p>Password Reset</p>
          </div>

          <div className="text-center mt-3 text-size">
            Your request is being processed.
          </div>
          <div className="mt-3 description-box text-size text-center">
            Please check your email for instructions. If you do not see any
            email from us then please check your spam folder. Failing that
            please contact Support
          </div>
        </div>
        <div className="text-center mt- bottom-margin font-weight" style={{marginTop: "20px"}}>
          <p className="text-support" onClick={supportClick}>Contact Support</p>
          {/* <Link to="/password-reset-1" className="btn  responsive-button">
            Continue
          </Link> */}
        </div>

        <div className="bottom-password-requested">
        <div className="button help" onClick={() => navigate("/support")}>
          Help
        </div>
        <div
          className="button privacy"
          onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
        >
          Privacy
        </div>
        <div
          className="button terms"
          onClick={() =>
            window.open("https://www.uonapp.com/user-agreement-for-uon")
          }
        >
          Terms
        </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
