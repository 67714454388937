import React, { useState, useEffect } from "react";
import "./User.scss";
import { useLocation } from "react-router-dom";
import {
  Button,
  OutlinedInput,
  Box,
  Typography,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TopBar from "../../components/topbar/topbar";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTable from "../../components/table/CustomTable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const User = () => {
  const [shortTable] = useState(true);
  const [showFilterBtn] = useState(false);
  const [showSearchBar] = useState(false);
  const [showPagination] = useState(false);
  const [tableHeight] = useState(true);
  const [userFilter] = useState(true);

  const columns = [
    { id: "firstName", label: "Item ID", minWidth: 170 },
    { id: "email", label: "Type", minWidth: 100 },
    {
      id: "__v",
      label: "Reason",
      minWidth: 170,
      align: "right",
      format: (value) => value,
    },
    { id: "date", label: "Reviewed", minWidth: 170 },
  ];
  const dummyRows = [
    {
      firstName: "10223",
      email: "Admin",
      __v: "Inappropiate",
      date: "07/05/22 @ 11:02am PT",
    },
    {
      firstName: "12345",
      email: "User",
      __v: "Inappropiate",
      date: "07/05/22 @ 11:02am PT",
    },
  ];
  const [editFields, setEditFields] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [data, setData] = useState([]);
  const location = useLocation();
  const reportedByData = location.state.reportedByData;
  const sourceAccountData = location.state.sourceAccountData;
  const [isToggled, setIsToggled] = useState(false);

  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    setFormData({
      ...formData,
      datingAccess: !isToggled === true ? "on" : "off",
    });
  };

  console.log(reportedByData, "reportedByData");

  useEffect(() => {
    if (reportedByData) {
      setFormData({
        firstname: reportedByData.firstName || "",
        lastname: reportedByData.lastName || "",
        email: reportedByData.email || "",
        phone: reportedByData.phone || "",
        status: reportedByData.userStatus || "",
        userType: reportedByData.userType || "",
        lastActive: reportedByData.lastActive || "1/1/2024 1:12PM PT",
        datingAccess: reportedByData.datingAccess || "",
      });
    }
  }, [reportedByData]);

  useEffect(() => {
    if (sourceAccountData) {
      setFormData({
        firstname: sourceAccountData.firstName || "",
        lastname: sourceAccountData.lastName || "",
        email: sourceAccountData.email || "",
        phone: sourceAccountData.phone || "",
        status: sourceAccountData.userStatus || "",
        userType: sourceAccountData.userType || "",
        lastActive: sourceAccountData.lastActive || "1/1/2024 1:12PM PT",
        datingAccess: sourceAccountData.datingAccess || "",
      });
    }
  }, [sourceAccountData]);
  function formatLastActiveDate(dateString) {
    const inputDate = new Date(dateString);

    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };

    return inputDate.toLocaleDateString("en-US", options) + " PT";
  }

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData);
      const dateObject = new Date(userData.lastActive);

      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      };

      const formattedDate = dateObject.toLocaleString("en-US", options) + " PT";

      setFormData({
        firstname: userData?.firstName,
        lastname: userData?.lastName,
        email: userData?.email,
        phone: userData?.phone,
        status: userData?.userStatus,
        userType: userData?.userType,
        lastActive: formattedDate,
        datingAccess: userData?.datingAccess,
      });
      setIsToggled(userData?.datingAccess === "on" ? true : false);
    }
    setData(dummyRows);
  }, [location.state]);

  const CustomIcon = () => {
    return <ArrowDropDownIcon style={{ marginRight: "10px" }} />;
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
    userType: "",
    lastActive: "",
    datingAccess: location.state.userData?.datingAccess,
  });
  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    status: false,
    userType: false,
    lastActive: false,
    datingAccess: false,
  });

  console.log(editableFields);

  const handleEditClick = (event) => {
    setEditFields(!editFields);
    if (editFields) {
      event.preventDefault();
      let userId = "";
      if (reportedByData) {
        userId = reportedByData._id;
      } else if (sourceAccountData) {
        userId = sourceAccountData._id;
      } else {
        userId = location.state.userData._id;
      }

      const datingAccess = isToggled ? "on" : "off";
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
          {
            firstName: formData?.firstname,
            lastName: formData?.lastname,
            email: formData?.email,
            phone: formData?.phone,
            userStatus: formData?.status,
            userType: formData?.userType,
            lastActive: formData?.lastActive,
            datingAccess: datingAccess,
          },
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          console.log("User information updated:", response.data);
          setEditableFields({
            name: false,
            email: false,
            phone: false,
            status: false,
            userType: false,
            lastActive: false,
            datingAccess: false,
          });
        })
        .catch((error) => {
          console.error("Error updating admin information:", error);
        });
    }
  };
  const myVariant = editFields ? undefined : "standard";
  const inputLabelStyle = {
    "&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
      color: "black",
    },
  };
  const inputStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  };
  const formStyles = { marginTop: 2 };

  const [selectedUserType, setSelectedUserType] = useState(formData?.userType);
  const [selectedUserStatus, setSelectedUserStatus] = useState(
    formData?.status
  );

  return (
    <>
      <div className="home-container">
        <Navbar />
        <div className="content-container">
          <Sidebar />
          <div className="main-card">
            <div className="topbar-container">
              <TopBar
                leftIcon={
                  <Link to="/users" className="links">
                    <ArrowBackIcon className="backIconStyling" />
                  </Link>
                }
                header={`Users / ${formData?.firstname}`}
              />
            </div>
            <div className="card-wrapper">
              <Container fluid>
                <Box className="card-header">
                  <Typography className="card-header-about">About</Typography>
                  <Button
                    variant={editFields ? "contained" : "outlined"}
                    className={editFields ? "save-button" : "edit-button"}
                    onClick={handleEditClick}
                  >
                    {editFields ? "Save" : "Edit"}
                  </Button>
                </Box>
                <Box
                  className="form"
                  display="flex"
                  flexDirection="column"
                  pt="24px"
                >
                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      First Name
                    </InputLabel>
                    {!editFields && (
                      <Input
                        sx={inputStyles}
                        value={formData?.firstname}
                        disableUnderline
                      />
                    )}
                    {editFields && (
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.firstname}
                        label="First Name"
                        onChange={(e) => {
                          // Update the formData when the input value changes
                          setFormData({
                            ...formData,
                            firstname: e.target.value,
                          });
                        }}
                      />
                    )}
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Last Name
                    </InputLabel>
                    {!editFields && (
                      <Input
                        sx={inputStyles}
                        value={formData?.lastname}
                        disableUnderline
                      />
                    )}
                    {editFields && (
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.lastname}
                        label="Username"
                        onChange={(e) => {
                          // Update the formData when the input value changes
                          setFormData({
                            ...formData,
                            lastname: e.target.value,
                          });
                        }}
                      />
                    )}
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={editFields ? false : true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Email
                    </InputLabel>
                    {!editFields && (
                      <Input
                        sx={inputStyles}
                        value={formData?.email}
                        disableUnderline
                      />
                    )}
                    {editFields && (
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.email}
                        label="Email"
                        onChange={(e) => {
                          // Update the formData when the input value changes
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    )}
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={true}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Last Active
                    </InputLabel>
                    {!editFields && (
                      <Input
                        sx={inputStyles}
                        value={formData?.lastActive}
                        disableUnderline
                      />
                    )}
                    {editFields && (
                      <OutlinedInput
                        id="component-outlined"
                        defaultValue={formData?.lastActive}
                        label="Last Active"
                        onChange={(e) => {
                          // Update the formData when the input value changes
                          setFormData({
                            ...formData,
                            lastActive: e.target.value,
                          });
                        }}
                      />
                    )}
                  </FormControl>
                  <FormControl
                    variant={myVariant}
                    disabled={!editFields}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Type
                    </InputLabel>
                    {!editFields ? (
                      <Input
                        sx={inputStyles}
                        value={formData?.userType}
                        disableUnderline
                      />
                    ) : (
                      <Select
                        id="component-outlined"
                        value={formData?.userType}
                        onChange={(e) => {
                          setSelectedUserType(e.target.value);
                          console.log(selectedUserType);
                          setFormData({
                            ...formData,
                            userType: e.target.value,
                          });
                        }}
                        label="Status"
                        IconComponent={CustomIcon}
                      >
                        <MenuItem value="User">User</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                      </Select>
                    )}
                  </FormControl>

                  <FormControl
                    variant={myVariant}
                    disabled={!editFields}
                    sx={formStyles}
                  >
                    <InputLabel
                      htmlFor="component-outlined"
                      sx={inputLabelStyle}
                    >
                      Status
                    </InputLabel>
                    {!editFields ? (
                      <Input
                        sx={inputStyles}
                        value={formData?.status}
                        disableUnderline
                      />
                    ) : (
                      <Select
                        id="component-outlined"
                        value={formData?.status}
                        onChange={(e) => {
                          setSelectedUserStatus(e.target.value);
                          console.log(selectedUserStatus);
                          setFormData({
                            ...formData,
                            status: e.target.value,
                          });
                        }}
                        label="Status"
                        IconComponent={CustomIcon}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Banned">Banned</MenuItem>
                        <MenuItem value="Suspended">Suspended</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                  <FormControl sx={{ marginTop: 1 }}>
                    {!editFields ? (
                      <Box>
                        <span className="dating-access">
                          Dating Module Access
                        </span>
                        <Box sx={{ color: "black" }}>
                          {formData?.datingAccess === "on"
                            ? "Active"
                            : "Inactive"}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="edit-dating-access">
                        <Box>Dating Module Access</Box>
                        <Box className="toggle-box">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isToggled}
                              onChange={toggleSwitch}
                            />
                            <span className="slider round"></span>
                          </label>
                        </Box>
                      </Box>
                    )}
                  </FormControl>
                </Box>
              </Container>
            </div>

            <div className="card-wrapper">
              <div className="card-header">
                <Typography className="card-header-about">
                  User Stats
                </Typography>
              </div>
              <CustomTable
                columns={columns}
                data={data}
                shortTable={shortTable}
                showFilterBtn={showFilterBtn}
                showSearchBar={showSearchBar}
                showPagination={showPagination}
                tableHeight={tableHeight}
                userFilter={userFilter}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
