import React, { useEffect, useState } from "react";
import "./NewSystemMessage.scss";
import { TextField, Grid, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import moment from "moment"; 
import "moment-timezone"; 

const NewSystemMessage = () => {
	const navigate = useNavigate();
	const [authToken, setAuthToken] = useState("");

	useEffect(() => {
		const token = localStorage.getItem("authToken");
		if (token) {
			setAuthToken(token);
		}
	}, []);

	const formatDate = (date) => {
		const [month, day, year] = date.split("/");
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	};

	const initialValues = {
		recipient: "All App Users",
		subject: "",
		message: "",
		date: "",
		time: "",
	};

	const dateValidation = Yup.string()
		.matches(
			/^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[01])\/\d{4}$/,
			"Please enter date in MM/DD/YYYY format"
		)
		.test("future-date", "Date must be today or a future date", (value) => {
			if (!value) return true;
			const today = moment().tz("America/Los_Angeles");
			const enteredDate = moment.tz(value, "MM/DD/YYYY", "America/Los_Angeles");
			return enteredDate.isSameOrAfter(today, "day");
		});

	const validationSchema = Yup.object().shape({
		recipient: Yup.string().required("Recipient is required"),
		subject: Yup.string().required("Subject is required"),
		message: Yup.string().required("Message is required"),
		date: dateValidation.required("Date is required"),
		time: Yup.string()
			.required("Time is required")
			.matches(
				/^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
				"Please enter time in HH:MM AM/PM format"
			)
			.test("future-time", "Time must be in the future", function (value) {
				const { date } = this.parent;
				if (!value || !date) return false;

				const enteredTime = moment(`${date} ${value}`, "MM/DD/YYYY h:mm A").tz("America/Los_Angeles", true); 
				const currentTime = moment().tz("America/Los_Angeles"); 

				return enteredTime.isAfter(currentTime);
			}),
	});

	const handleSendButtonClick = (values) => {
		const { recipient, subject, message, date, time } = values;
		const formattedDate = formatDate(date);
		
		axios
			.post(
				`${process.env.REACT_APP_BASE_URL}/api/v1/system_message`,
				{
					recipient,
					subject,
					messageContent: message,
					date: formattedDate,
					time,
				},
				{
					headers: {
						Authorization: `${authToken}`,
					},
				}
			)
			.then((response) => {
				console.log("System message sent:", response.data);
				toast.success("System message created successfully");
				navigate("/system_message");
			})
			.catch((error) => {
				console.error("Error sending system message:", error);
			});
	};

	return (
		<div className="message-container">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					handleSendButtonClick(values);
					setSubmitting(false);
				}}
			>
				{({ isSubmitting }) => (
					<Form style={{ marginTop: -60 }}>
						<Grid container spacing={3}>
							<Grid item lg={2} xs={3} md={3}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id="recipient-label">To</InputLabel>
									<Field name="recipient" as={Select} label="To" labelId="recipient-label">
										<MenuItem value="All App Users">All App Users</MenuItem>
									
									</Field>
								</FormControl>
							</Grid>
							<Grid item lg={8} xs={2} md={2}></Grid>
							<Grid item lg={3} xs={2} md={3}>
								<Field name="subject">
									{({ field, meta }) => (
										<TextField
											{...field}
											className="subject_field"
											fullWidth
											variant="outlined"
											label="Subject"
											error={meta.touched && meta.error}
											helperText={meta.touched && meta.error}
										/>
									)}
								</Field>
							</Grid>
							<Grid item xs={12}>
								<Field name="message">
									{({ field, meta }) => (
										<TextField
											{...field}
											className="message_field"
											placeholder="Start typing your message here..."
											fullWidth
											multiline
											minRows={10}
											maxRows={50}
											variant="outlined"
											label="Message"
											error={meta.touched && meta.error}
											helperText={meta.touched && meta.error}
										/>
									)}
								</Field>
							</Grid>
							<Grid item xs={12} md={2}>
								<Field name="date">
									{({ field, meta }) => (
										<TextField
											{...field}
											className="date_field"
											fullWidth
											variant="outlined"
											label="Date"
											error={meta.touched && meta.error}
											helperText={meta.touched && meta.error}
										/>
									)}
								</Field>
							</Grid>
							<Grid item xs={12} md={2}>
								<Field name="time">
									{({ field, meta }) => (
										<TextField
											{...field}
											className="time_field"
											fullWidth
											variant="outlined"
											label="Time"
											error={meta.touched && meta.error}
											helperText={meta.touched && meta.error}
										/>
									)}
								</Field>
							</Grid>
							<Grid item lg={12} xs={3} md={3}></Grid>
							<Grid item lg={12} xs={12} md={2}>
								<button
									type="submit"
									className="send_button"
									disabled={isSubmitting}
								>
									{isSubmitting ? "Sending..." : "Send"}
								</button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default NewSystemMessage;
