import React, { useState, useEffect } from "react";
import "./EditAdmin.scss";
import { Error as ErrorIcon } from "@mui/icons-material";
import axios from "axios";


const EditAdmin = ({ rowData }) => {
  
  
  const [authToken, setAuthToken] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [editableFields, setEditableFields] = useState({
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (rowData) {
      setFormData({
        firstName: rowData?.firstName,
        lastName: rowData?.lastName,
        email: rowData?.email,
        phone: rowData?.phone,
      });
    }
  }, [rowData]);
  const handleCancelClick = () => {
   
    window.location.href = "/admin-users";
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const userId = rowData._id;

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
        {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          phone: formData?.phone,
          userType: "Admin",
        },
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Admin information updated:", response.data);
        window.location.href = "/admin-users";
      })
      .catch((error) => {
        console.error("Error updating admin information:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setError(error.response.data.error);
        } else {
          setError("An error occurred. Please try again later.");
        }
      });
  };

  const handleFieldClick = (fieldName) => {
    setEditableFields({
      ...editableFields,
      [fieldName]: true,
    });
    
  };

  return (
    <div className="wrapper-card-edit">
      <div className="header-card-edit">
        <span className="header-text-edit">Manage Admin User</span>
        <ErrorIcon className="icon-edit" />
      </div>
      <div className="card-field-edit">
        <input
          type="text"
          className="custom-input-edit form-control"
          placeholder="First Name"
          name="firstName"
          value={formData?.firstName}
          readOnly={!editableFields?.firstName}
          onClick={() => handleFieldClick("firstName")}
          onChange={(e) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
        />
        <input
          type="text"
          className="custom-input-edit form-control"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          readOnly={!editableFields.lastName}
          onClick={() => handleFieldClick("lastName")}
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
        />

        <input
          type="email"
          className="custom-input-edit form-control"
          placeholder="Email"
          name="email"
          value={formData.email}
          readOnly={!editableFields.email}
          onClick={() => handleFieldClick("email")}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <input
          type="text"
          className="custom-input-edit form-control"
          placeholder="Phone"
          name="phone"
          value={formData.phone}
          readOnly={!editableFields.phone}
          onClick={() => handleFieldClick("phone")}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
      </div>
      <div className="card-button-container-edit">
       
          <button
            className="custom-button-back-edit"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
       
        <button
          className="custom-button-create-edit"
          onClick={handleFormSubmit}
        >
          Save
        </button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default EditAdmin;
