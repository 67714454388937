import React, { useState, useEffect, useContext } from "react";
import "./AdminUser.scss";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import CustomTable from "../../components/table/CustomTable";
import EditAdmin from "../EditAdmin/EditAdmin";
import UpdatePassword from "../UpdatePassword/UpdatePassword";
import { Tabcontext } from "../../Context/TabContext";
import { useNavigate } from "react-router-dom";
const columns = [
  { id: "firstName", label: "First Name", minWidth: 170 },
  { id: "lastName", label: "Last Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "phone", label: "Phone Number", minWidth: 100 },
  {
    id: "lastActive",
    label: "Last Active",
    minWidth: 100,
    format: (value) => {
      const inputDate = new Date(value);

      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      };

      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      return formattedDate + " PT";
    },
  },
];

const AdminUser = () => {
  const navigate = useNavigate();
  const { tabValue } = useContext(Tabcontext);
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAdminButton] = useState(true);
  const [showUpdatePassword, setUpdatePassword] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFilterBtn] = useState(true);
  const [showSearchBar] = useState(true);
  const [showPagination] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabWidth] = useState(true);

  const handleAdminButtonClick = () => {
    navigate("/add-admin");
  };

  const handleUpdatePasswordClick = () => {
    setUpdatePassword(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    if (tabValue) {
      fetchData(tabValue);
    } else {
      fetchData(selectedTab);
    }
  }, [authToken, pageNumber, rowsPerPage, selectedTab, tabValue]);

  const fetchData = async (tabIndex) => {
    if (authToken) {
      const tabNames = ["Active", "InActive"];
      const tabName = tabNames[tabIndex];
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/users/all?userType=Admin&userStatus=${
            tabName ? tabName : ""
          }`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          const modifiedData = response.data.data.users.map((user) => ({
            ...user,
            name: `${user?.firstName} ${user?.lastName}`,
          }));
          setData(modifiedData);
          setTotalRecords(response?.data?.results);
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  };

  const handleRowSelect = (rowData) => {
    setSelectedRow(rowData);
  };

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar
              header="Admin Users"
              showButton={showAdminButton}
              onButtonClick={handleAdminButtonClick}
              onUpdateButtonClick={handleUpdatePasswordClick}
            />
          </div>

          <div className="table-container">
            <CustomTable
              columns={columns}
              data={data}
              context="admin-user"
              onRowClick={handleRowSelect}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
              labels={["Active", "Inactive"]}
              showFilterBtn={showFilterBtn}
              showSearchBar={showSearchBar}
              showPagination={showPagination}
              changeTab={setSelectedTab}
              tabWidth={tabWidth}
            />

            {selectedRow && (
              <div className="blur-background">
                <EditAdmin rowData={selectedRow} />
              </div>
            )}

            {showUpdatePassword && (
              <div className="blur-background">
                <UpdatePassword setUpdatePassword={setUpdatePassword} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUser;
