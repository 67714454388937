import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
// import SearchField from "../../components/search/SearchField";
import CustomTable from "../../components/table/CustomTable";
import "./BussinessUser.scss";

const columns = [
  { id: "firstName", label: "Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "birthday",
    label: "Age",
    minWidth: 170,
    align: "right",
    format: (value) => {
      const birthYear = new Date(value).getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear;
    },
  },
  {
    id: "flagItemCount",
    label: "Number of Flagged Items",
    minWidth: 170,
    align: "right",
    format: (value) => value,
  },
  {
    id: "lastActive",
    label: "Last Active",
    minWidth: 170,
    format: (value) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(value).toLocaleString(undefined, options);
    },
  },

  { id: "userStatus", label: "Status", minWidth: 170 },
];
const BussinessUser = () => {
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users?page=${pageNumber}&per_page=${rowsPerPage}&userType=BusinessUser`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          setData(response.data.data.users);
          setTotalRecords(response?.data?.results)
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  }, [authToken, pageNumber, rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar header="Uon Business Accounts" />
          </div>

          {/* <SearchField /> */}

          <div className="table-container">
            <CustomTable
              columns={columns}
              data={data}
              context="user"
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessUser;
