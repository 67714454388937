import React, { useState, useEffect } from "react";
import "./SingleFlagItem.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import { Box } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import SimpleCard from "../../components/SimpleCard/SimpleCard";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";

function formatDate(inputDateStr) {
  const inputDate = new Date(inputDateStr);

  const formattedDate = `${
    inputDate.getMonth() + 1
  }/${inputDate.getDate()}/${inputDate.getFullYear()} ${inputDate.getHours()}:${inputDate.getMinutes()}${
    inputDate.getHours() >= 12 ? " PM" : " AM"
  } PT`;

  return formattedDate;
}

const SingleFlagItem = () => {
  const navigate = useNavigate();
  const [showSaveButton] = useState(true);
  const [buttonOn, setButtonOn] = useState("");
  const [authToken] = useState(localStorage.getItem("authToken"));
  const [formData, setFormData] = useState({
    reportedBy: "",
    sourceAccount: "",
    itemType: "",
    createdAt: "",
    summary: "",
  });

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData, "userData");
      setFormData({
        reportedBy: userData.reportedBy,
        sourceAccount: userData.sourceAccount,
        itemType: userData.itemType,
        createdAt: formatDate(userData.createdAt),
        summary: userData.summary,
        itemID: userData.itemId,
        status: userData.Status,
        reportedByData: userData.reportedByData,
        sourceAccountData: userData.sourceAccountData,
      });
      setButtonOn(userData.Status);
    }
  }, [location.state]);

  console.log(formData);

  const buttonStyles = {
    borderRadius: "10px",
    backgroundColor: "transparent",
    color: "#0159c7",
    border: "1px solid black",
    width: "101px",
    border: "1px solid blue",
    height: "20px",
    padding: "10px",
    textTransform: "none",
    marginRight: "10px",
  };

  const getStatusButtonStyles = (status) => ({
    ...buttonStyles,
    backgroundColor: formData.status === status ? "blue" : "white",
    color: formData.status === status ? "white" : "blue",
    "&:hover": {
      backgroundColor: formData.status === status ? "blue" : "white",
      color: formData.status === status ? "white" : "blue",
    },
  });

  const statusClick = (label) => {
    setButtonOn(label);
  };

  const onButtonClick = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/flagitems/${formData.itemID}`,
        {
          flagStatus: buttonOn,
        },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        navigate("/flag-items");
      })
      .catch((error) => {
        console.error("Error updating flag status:", error);
      });
  };

  const onMenuClick = (number) => {
    if (number === "first") {
      navigate(`/users/user/${formData?.reportedByData?._id}`, {
        state: {
          reportedByData: formData?.reportedByData,
        },
      });
    } else if (number === "second") {
      navigate(`/users/user/${formData?.sourceAccountData?._id}`, {
        state: {
          sourceAccountData: formData?.sourceAccountData,
        },
      });
    } else if (number === "third") {
      navigate(`/users/user/${formData?.reportedByData?._id}`, {
        state: {
          reportedByData: formData?.reportedByData,
        },
      });
    }
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar
              leftIcon={
                <Link to="/flag-items" className="links">
                  <ArrowBackIcon className="backIconStyling" />
                </Link>
              }
              showSaveButton={showSaveButton}
              onButtonClick={onButtonClick}
              header={`Flagged Items / Item ID ${formData.itemID}`}
            />
          </div>

          <div className="search-and-dropdown-container">
            <div className="search-field">{/* <SearchField /> */}</div>
            <div className="enum-field-container">
              <div className="enum-field"></div>
            </div>
          </div>
          <Box width="100%">
            <Container
              className="mt-5 d-flex flag-container-style"
              style={{
                marginLeft: "15px",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <Row>
                <Col md={6} className="mb-3 d-flex justify-content-end">
                  <SimpleCard
                    heading="Reported by"
                    data={formData.reportedBy}
                    icon={true}
                    onMenuClick={() => onMenuClick("first")}
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex justify-content-start">
                  <SimpleCard
                    heading="User Reported"
                    data={formData?.reportedBy}
                    icon={true}
                    onMenuClick={() => onMenuClick("third")}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={6} className="mb-3 d-flex justify-content-end">
                  <SimpleCard heading="Report Type" data={formData.itemType} />
                </Col>
                <Col md={6} className="mb-3 d-flex justify-content-start">
                  <SimpleCard
                    heading="Reason reported"
                    data={formData?.summary}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={6} className="mb-3 d-flex justify-content-end">
                  <SimpleCard
                    heading="Reported created"
                    data={formData?.createdAt}
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex justify-content-start">
                  <SimpleCard
                    heading="Status"
                    data={
                      <Box>
                        <Box display='flex' justifyContent='space-evenly'>
                          
                        <button className={buttonOn === "Pending" ? "onButton" : "offButton"} onClick={() => statusClick("Pending")}>Pending</button>
                        <button className={buttonOn === "Removed" ? "onButton" : "offButton"} onClick={() => statusClick("Removed")}>Removed</button>
                        <button className={buttonOn === "Dismissed" ? "onButton" : "offButton"} onClick={() => statusClick("Dismissed")}>Dismissed</button>
                      </Box>

                      
                    </Box>
                    }
                  />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={6} className="mb-3 d-flex justify-content-end">
                  <SimpleCard
                    heading="Reviewed By"
                    data={formData?.sourceAccount}
                    icon={true}
                    onMenuClick={() => onMenuClick("second")}
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex justify-content-start">
                  <SimpleCard heading="Reviewed" data={formData?.createdAt} />
                </Col>
              </Row>

              <Row className="justify-content-start">
                <Col
                  md={6}
                  className="mb-3 d-flex justify-content-end"
                  style={{ width: "424px" }}
                >
                  <SimpleCard
                    heading="Content Reported"
                    data={
                      <Box>
                        <img
                          src="IMG-9805.JPG"
                          alt="logo"
                          style={{ width: "64px" }}
                        />
                      </Box>
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SingleFlagItem;
