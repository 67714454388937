import React, { useState, useEffect, useContext } from "react";
import "./FlagItem.scss";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import CustomTable from "../../components/table/CustomTable";
import { Tabcontext } from "../../Context/TabContext";

const renderStatusButton = (flagStatus) => {
  let buttonStyles = {
    width: "99px",
    height: "24px",
    padding: "10px 24px",
    borderRadius: "100px",
    gap: "8px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#FFFFFF",
    background: "#0059C7",

    border: "1px solid #1F1F1F1F",
  };

  if (flagStatus === "Disabled") {
    buttonStyles = {
      width: "104px",
      height: "24px",
      padding: "10px 24px",
      borderRadius: "100px",
      gap: "8px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      textAlign: "center",
      color: "#1B1B1E",
      opacity: "0.38",
      border: "1px solid #1F1F1F",
      background:
        "linear-gradient(0deg, rgba(0, 89, 199, 0), rgba(0, 89, 199, 0)), linear-gradient(0deg, rgba(31, 31, 31, 0.12), rgba(31, 31, 31, 0.12))",
    };
  }
  buttonStyles.display = "flex";
  buttonStyles.justifyContent = "center";
  buttonStyles.alignItems = "center";
  return (
    <button style={buttonStyles}>
      {flagStatus === "Enabled" ? "Enabled" : "Disabled"}
    </button>
  );
};

const columns = [
  { id: "itemId", label: "Item ID", minWidth: 100 },
  { id: "itemType", label: "Type", minWidth: 100 },
  { id: "userReported", label: "User Reported", minWidth: 100 },
  { id: "reportedBy", label: "Reported By", minWidth: 100 },
  { id: "summary", label: "Reason", minWidth: 100 },
  {
    id: "reportCreated", label: "Report Created", minWidth: 100,
    format: (value) => {
      const inputDate = new Date(value);

      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      };

      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      return formattedDate + " PT";
    }, },
  { id: "sourceAccount", label: "Reviewed By", minWidth: 100 },
  {
    id: "createdAt",
    label: "Reviewed",
    minWidth: 170,
    format: (value) => {
      const inputDate = new Date(value);

      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      };

      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      return formattedDate + " PT";
    },
  },
];
const FlagItem = () => {
  const {tabValue} = useContext(Tabcontext);
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFilterBtn] = useState(true);
  const [showSearchBar] = useState(true);
  const [showPagination] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);


  useEffect(() => {
    if(tabValue){
      fetchData(tabValue)
    }else{
    fetchData(selectedTab)
    }
  }, [authToken, pageNumber, rowsPerPage,selectedTab, tabValue])

  const fetchData = async (tabIndex) => {
    if (authToken) {
      const tabNames = ['Pending', 'Removed', 'Dismissed'];
      const tabName = tabNames[tabIndex];
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/v1/flagitems?flagStatus=${tabName ? tabName : ""}`, {
          headers: {
            Authorization: `${authToken}`,
          },
        })
        .then((response) => {
          const modifiedData = response.data.data.reportItems.map((flagItem) => ({
            ...flagItem,
            itemId: flagItem._id,
            userReported: flagItem.reportedBy.firstName + " " + flagItem.reportedBy.lastName,
            reportedBy: flagItem.reportedBy.firstName + " " + flagItem.reportedBy.lastName,
            reportCreated: flagItem.createdAt,
            sourceAccount:flagItem.sourceAccount.firstName +" " + flagItem.sourceAccount.lastName,
            Status: flagItem.flagStatus,
            reportedByData: flagItem.reportedBy,
            sourceAccountData: flagItem.sourceAccount
          }));
          setData(modifiedData);
          setTotalRecords(response?.data?.results);
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  }

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar header="Flagged Items" />
          </div>

          <div className="search-and-dropdown-container">
            <div className="search-field">{/* <SearchField /> */}</div>
            <div className="enum-field-container">
              <div className="enum-field">
              </div>
            </div>
          </div>
          <div className="table-container">
            <CustomTable
              columns={columns}
              data={data}
              context="flagitem"
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
              labels={['Pending', 'Removed', 'Dismissed']}
              showFilterBtn={showFilterBtn}
              showSearchBar={showSearchBar}
              showPagination={showPagination}
              changeTab={setSelectedTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlagItem;



