import React, { useState } from "react";
import "./signin.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Signin = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { state } = useLocation();
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const emailSubmitted = state?.isEmailSubmitted;
  const prevEmail = state?.email;
  const [email, setEmail] = useState(prevEmail);
  const useremail = state?.useremail;
  const [error, setError] = useState(false);
  const emailback = state?.emailback;
  const prevbackemail = state?.prevbackemail;

  const handleContinueClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+\w+$/;
    if (!email) {
      setLoginError("Email is required to login");
    } else if (!emailRegex.test(email)) {
      setLoginError("Please check your email and try again");
    } else {
      setLoginError("");
      setIsEmailSubmitted(true);
    }
  };

  const emailBoxClick = () => {
    setIsEmailSubmitted(false);
    navigate("/sign-in", {
      state: {
        useremail: email,
      },
    });
  };
  const handleSignIn = async (event) => {
    try {
      event.preventDefault();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/login`,
        {
          email: email,
          password: password,
          userType: "Admin",
        }
      );
      console.log(response.data.user);
      if (response.data.message === "Logged In Successfully") {
        console.log("Login successful!");
        localStorage.setItem("authToken", response.data.token);
        const userData = JSON.stringify(response.data.user);
        localStorage.setItem("userInfo", userData);
        console.log("Authentication token:", response.data.token);
        navigate("/flag-items");
        window.location.reload();
        setEmail("");
        setPassword("");
      } else {
        console.log("Login failed!");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      setError(true);

      if (error.response && error.response.data && error.response.data.error) {
        setLoginError(error.response.data.error);
      } else {
        setLoginError("An error occurred. Please try again later.");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const forgetButtonClick = () => {
    navigate("/password-reset", {
      state: {
        email: email,
        isEmailSubmitted: isEmailSubmitted,
      },
    });
  };

  const handleClickSupport = () => {
    navigate("/support", {
      state: {
        backemail: true,
        email: email,
      },
    });
  };

  return (
    <div className="wrapper-all">
      <div
        className={
          emailback || isEmailSubmitted || emailSubmitted
            ? "first-half2"
            : "first-half"
        }
      >
        <div className="logo" style={{ marginTop: "-15px" }}>
          <div className="avatar-container">
            <span className="custom-avatar">
              <img src="IMG-9805.JPG" alt="logo" style={{ width: "64px" }} />
            </span>
          </div>
        </div>
        <div className="signin-title">
          <p>Sign in</p>
        </div>

        <div className="title-body">
          <p style={{ marginTop: "-13px", marginBottom: "27px" }}>
            Sign into your UON Account
          </p>
          {emailback || isEmailSubmitted || emailSubmitted ? (
            <div className="dropdown-show" onClick={emailBoxClick}>
              <EmailIcon style={{ color: "white" }} />
              <button
                className="custom-dropdown-button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {email || prevEmail || prevbackemail}
                <ArrowDropDownIcon
                  className="arrow-icon"
                  style={{ color: "white" }}
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {!emailback && !isEmailSubmitted && !emailSubmitted ? (
        <div className="field">
          <MailOutlineIcon />
          <input
            type="text"
            name="email"
            id="userName"
            placeholder="Email"
            defaultValue={useremail}
            onChange={handleInputChange}
          />
        </div>
      ) : null}

      {emailback || isEmailSubmitted || emailSubmitted ? (
        <div className={error ? "errorfield" : "field"}>
          <KeyIcon className="key-icon" />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            onChange={handleInputPassword}
          />
          <div className="material-icon" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <VisibilityIcon className="visibility-icon" />
            ) : (
              <VisibilityOffIcon className="visibility-off-icon" />
            )}
          </div>
        </div>
      ) : null}
      {loginError && <div className="error-message-signin">{loginError}</div>}
      <div className="button-container">
        {emailback || isEmailSubmitted || emailSubmitted ? (
          <button className="forgot-button" onClick={forgetButtonClick}>
            Forget Password
          </button>
        ) : null}
        {!isEmailSubmitted && !emailSubmitted ? (
          <button
            type="button"
            className="button-common"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        ) : (
          <button
            type="button"
            className="button-common"
            onClick={handleSignIn}
          >
            Sign in
          </button>
        )}
      </div>

      <div
        className={
          emailback || isEmailSubmitted || emailSubmitted
            ? "bottom"
            : "new-bottom"
        }
      >
        <div className="button help" onClick={handleClickSupport}>
          Help
        </div>
        <div
          className="button privacy"
          onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
        >
          Privacy
        </div>
        <div
          className="button terms"
          onClick={() =>
            window.open("https://www.uonapp.com/user-agreement-for-uon")
          }
        >
          Terms
        </div>
      </div>
    </div>
  );
};

export default Signin;
