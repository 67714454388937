import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Signin from "./pages/Signin/Sigin";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import PasswordVerify from "./pages/PasswordReset/PasswordVerify";
import PasswordResetRequest from "./pages/PasswordReset/PasswordResetRequest";
import ResetPassword from "./pages/PasswordReset/ResetPassword";
import UserList from "./pages/UserList/UserList";
import SystemMessage from "./pages/SystemMessage/SystemMessage.jsx";
import BussinessUser from "./pages/BussinessUser/BussinessUser";
import SystemSettings from "./pages/Settings/SystemSetting";
import AdminUser from "./pages/AdminUser/AdminUser";
import FlagItem from "./pages/FlagItem/FlagItem";
import User from "./pages/User/User";
import AddAdmin from "./pages/AddAdmin/AddAdmin";
import EditAdmin from "./pages/EditAdmin/EditAdmin";
import SingleFlagItem from "./pages/SingleFlagItem/SingleFlagItem";
import "bootstrap/dist/css/bootstrap.css";
import { AppProvider } from "./Context/index.js";
import PrivateRoutes from "./PrivateRoutes";
import SingleAdminUser from "./pages/SingleAdminUser/SingleAdminUser";
import DatingEvent from "./pages/DatingEvent/DatingEvent";
import CreateEvent from "./pages/CreateEvent/CreateEvent";
import ModifyQuestionBank from "./pages/ModifyQuestionBank/ModifyQuestionBank";
import SingleDatingEvent from "./pages/SingleDatingEvent/SingleDatingEvent";
import { TabProvider } from "./Context/TabContext";
import Support from "./pages/Support/Support";
import NotFound from "./pages/NotFound/NotFound";
import UpdatePassword from "./pages/UpdatePassword/UpdatePassword";
import Events from "./pages/Events/Events";
import AddEvent from "./pages/AddEvent/AddEvent";
import SingleEvent from "./pages/SingleEvent/SingleEvent";
import SingleSystemMessage from "./pages/SingleSystemMessage/SingleSystemMessage";
import HealthCheck from "./components/HealthCheck.jsx";
import { ToastContainer } from "react-toastify";

function App() {
	const [authToken] = useState(localStorage.getItem("authToken"));

	const isAuthenticated = () => {
		return authToken !== null;
	};

	const SignInRoute = ({ element }) => {
		return isAuthenticated() ? <Navigate to="/flag-items" replace /> : element;
	};

	return (
		<>
			<AppProvider>
				<ToastContainer />
				<TabProvider>
					<BrowserRouter>
						<Routes>
							<Route
								path="/"
								element={<PrivateRoutes Component={FlagItem} />}
							/>
							<Route
								path="/users"
								element={<PrivateRoutes Component={UserList} />}
							/>
							<Route
								path="/business-users"
								element={<PrivateRoutes Component={BussinessUser} />}
							/>
							<Route
								path="/users/user/:id"
								element={<PrivateRoutes Component={User} />}
							/>
							<Route
								path="/setting"
								element={<PrivateRoutes Component={SystemSettings} />}
							/>
							<Route
								path="/system_message"
								element={<PrivateRoutes Component={SystemMessage} />}
							/>
							<Route
								path="/admin-users"
								element={<PrivateRoutes Component={AdminUser} />}
							/>
							<Route
								path="/admin-users/admin-user/:id"
								element={<PrivateRoutes Component={SingleAdminUser} />}
							/>
							<Route
								path="/eventmanager"
								element={<PrivateRoutes Component={DatingEvent} />}
							/>
							<Route
								path="/eventmanager/create-event"
								element={<PrivateRoutes Component={CreateEvent} />}
							/>
							<Route
								path="/eventmanager/modify-question-bank"
								element={<PrivateRoutes Component={ModifyQuestionBank} />}
							/>
							<Route
								path="/eventmanager/date-event"
								element={<PrivateRoutes Component={SingleDatingEvent} />}
							/>
							<Route
								path="/events/event"
								element={<PrivateRoutes Component={SingleEvent} />}
							/>
							<Route
								path="/system_message/message"
								element={<PrivateRoutes Component={SingleSystemMessage} />}
							/>
							<Route
								path="/flag-items"
								element={<PrivateRoutes Component={FlagItem} />}
							/>
							<Route
								path="/flag-items/flag/:id"
								element={<PrivateRoutes Component={SingleFlagItem} />}
							/>
							<Route
								path="/add-admin"
								element={<PrivateRoutes Component={AddAdmin} />}
							/>
							<Route
								path="/update-admin"
								element={<PrivateRoutes Component={EditAdmin} />}
							/>
							<Route
								path="/update-password"
								element={<PrivateRoutes Component={UpdatePassword} />}
							/>
							<Route
								path="/events"
								element={<PrivateRoutes Component={Events} />}
							/>
							<Route
								path="/events/add-event"
								element={<PrivateRoutes Component={AddEvent} />}
							/>
							<Route
								path="*"
								element={<PrivateRoutes Component={NotFound} />}
							/>
							<Route
								path="sign-in"
								element={<SignInRoute element={<Signin />} />}
							/>

							<Route
								path="/password-reset"
								element={<SignInRoute element={<PasswordReset />} />}
							/>
							<Route
								path="/password-verify"
								element={<SignInRoute element={<PasswordVerify />} />}
							/>
							<Route
								path="/password-reset-request"
								element={<SignInRoute element={<PasswordResetRequest />} />}
							/>
							<Route
								path="/reset-password"
								element={<SignInRoute element={<ResetPassword />} />}
							/>
							<Route
								path="/support"
								element={<SignInRoute element={<Support />} />}
							/>
							<Route
								path="/health"
								element={<SignInRoute element={<HealthCheck />} />}
							/>
							<Route
								path="*"
								element={<SignInRoute element={<NotFound />} />}
							/>
						</Routes>
					</BrowserRouter>
				</TabProvider>
			</AppProvider>
		</>
	);
}

export default App;
