import React, { useState, useEffect } from "react";
import "./ModifyQuestionBank.scss";
import { useLocation } from "react-router-dom";
import { Box, Typography, TextField } from '@mui/material';
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TopBar from "../../components/topbar/topbar";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const User = () => {
  const dummyRows = [
  {
    firstName: "10223",
    email: "Admin",
    __v: 123,
    date: "2023-08-17",
  },
  {
    firstName: "12345",
    email: "User",
    __v: 456,
    date: "2023-08-16",
  },
];
  const [editFields, setEditFields] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [data, setData] = useState([]);
  const location = useLocation();

  console.log(data)
  

    useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData)
      const dateObject = new Date(userData.lastActive);
      const formattedDate = dateObject.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
      });
    
      setFormData({
        firstname: userData?.firstName,
        lastname: userData?.lastName,
        email: userData?.email,
        phone: userData?.phone,
        status: userData?.userStatus,
        userType: userData?.userType,
        lastActive: formattedDate,
      });
    }
    setData(dummyRows);
  }, [location.state]);


  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
    userType: "",
    lastActive: "",
  });
  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    status: false,
    userType: false,
    lastActive: false,
  });

  console.log(editableFields);

  const handleEditClick = (event) => {
    navigate("/modify-question-bank");
    //  setEditFields(!editFields);
    //  if(editFields){
    //       event.preventDefault();
    // const userId = location.state.userData._id;

    // axios
    //   .patch(
    //     `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
    //     {
    //       firstName: formData?.firstname,
    //       lastName: formData?.lastname,
    //       email: formData?.email,
    //       phone: formData?.phone,
    //       userStatus: formData?.status,
    //       userType: formData?.userType,
    //       lastActive: formData?.lastActive,
    //     },
    //     {
    //       headers: {
    //         Authorization: `${authToken}`,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log("User information updated:", response.data);
    //     setEditableFields({
    //       name: false,
    //       email: false,
    //       phone: false,
    //       status: false,
    //       userType: false,
    //       lastActive: false,
    //     });
    //     navigate("/users");
    //   })
    //   .catch((error) => {
    //     console.error("Error updating admin information:", error);
    //   });
    //  }
  }

  const handleCancelClick = () => {
    navigate("/eventmanager");
  }
 


  return (
    <>
     <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
      <div className="main-card">
      <div className="topbar-container">
              <TopBar
                leftIcon={
                  <Link to="/eventmanager" className="links">
                    <ArrowBackIcon className="backIconStyling"/>
                  </Link>
                }
                header={`Edit Speed Dating Questions`}
              />
            </div>
        <div className="card-wrapper" style={{width: "92%"}}>
          <Container fluid>
          <Box className='card-header'>
            <Typography className="card-header-about">
              Question Bank
            </Typography>
            <div>
            <button 
              variant={'outlined'}
              className="cancel-button"
              onClick={handleCancelClick}>
              Cancel
            </button>
            <span style={{marginLeft: "20px"}}>
            <button 
              variant='contained'
              className="large-save-button"
              onClick={handleEditClick}>
              Save
            </button>
            </span>
            </div>
          </Box>
          <Box className='form' display='flex' flexDirection='column' pt='24px'>
          <Typography className="card-header-question-bank">
              Current Question Bank
            </Typography>
            <div style={{marginTop: "20px", marginBottom: "20px"}}>
            <TextField id="outlined-basic" label="example.csv" variant="outlined" />
            </div>

            <Typography className="card-header-question-bank">
              Replace Question Bank
            </Typography>
            <div style={{marginTop: "20px", marginBottom: "20px"}}>
            <TextField id="outlined-basic" label="example.csv" variant="outlined" />
            </div>
            <p style={{width: "500px", color: "grey"}}>Note: Uploading a new file will replace the current question bank. </p>
          </Box>
          </Container>

        </div>
        
      </div>
      </div>
      </div>
    </>
  );
};


export default User;
