import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import CustomTable from "../../components/table/CustomTable";
import "./userList.scss";
import { Tabcontext } from "../../Context/TabContext";
import { useContext } from "react";

const columns = [
  { id: "firstName", label: "First Name", minWidth: 170 },
  { id: "lastName", label: "Last Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "age", label: "Age", minWidth: 100 },
  { id: "userType", label: "Type", minWidth: 100 },
  {
    id: "lastActive",
    label: "Last Active",
    minWidth: 100,
    format: (value) => {
      const inputDate = new Date(value);

      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      };

      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      return formattedDate + " PT";
    },
  },
  {
    id: "flagItemCount",
    label: "Number of Flagged Items",
    minWidth: 170,
    align: "right",
    format: (value) => value,
  },
];

const UserList = () => {
  const { tabValue } = useContext(Tabcontext);
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showFilterBtn] = useState(true);
  const [showSearchBar] = useState(true);
  const [showPagination] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    if (tabValue) {
      fetchData(tabValue);
    } else {
      fetchData(selectedTab);
    }
  }, [authToken, pageNumber, rowsPerPage, selectedTab, tabValue]);

  const fetchData = async (tabIndex) => {
    if (authToken) {
      const tabNames = ["Active", "Suspended", "Banned"];
      console.log(tabIndex);
      const tabName = tabNames[tabIndex];
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/users?userType=User&userStatus=${
            tabName ? tabName : ""
          }`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          const modifiedData = response.data.data.users.map((user) => ({
            ...user,
            age:user.age,
            flagItemCount: user.flagItemCount,
            LastActive: user.lastActive,
            Status: user.userStatus,
            datingAccess: user.datingAccess
          }));
          setTotalRecords(response?.data?.results);
          setData(modifiedData);
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  };

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar header="Uon Users" />
          </div>

          <div className="table-container">
            <CustomTable
              columns={columns}
              data={data}
              context="user"
              labels={["Active", "Suspended", "Banned"]}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
              showFilterBtn={showFilterBtn}
              showSearchBar={showSearchBar}
              showPagination={showPagination}
              changeTab={setSelectedTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
