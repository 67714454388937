import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import "./AddAdmin.scss";
import errorIcon from "../../assets/images/error.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Please check your email and try again")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
});
const formatPhoneNumber = (phoneNumber) => {
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  const formattedNumber = cleanedNumber.replace(
    /^(\d{3})(\d{3})(\d{4})$/,
    "($1)-$2-$3"
  );

  return formattedNumber;
};

const AddAdmin = ({ setShowAddAdmin }) => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [error, setError] = useState("");
  const [height, setHeight] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const handleCreateAdmin = (values) => {
    if (authToken) {
      const formattedPhone = formatPhoneNumber(values.phone);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users/admin_users`,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: formattedPhone,
            userType: "Admin",
            password: "Admin@123",
          },
          {
            headers: {
              Authorization: authToken,
            },
          }
        )
        .then((response) => {
          console.log("Admin created successfully:", response.data);

          navigate("/admin-users");
        })
        .catch((error) => {
          console.error("Error creating user:", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            setError(error.response.data.error);
          } else {
            setError("An error occurred. Please try again later.");
          }
        });
      // setShowAddAdmin(false);
    }
  };

  console.log(height, "height");

  return (
    <div className="blur-background">
      <div className="wrapper-card-add" style={{ height: "490px" }}>
        <div className="header-card-add">
          <span className="header-text-add">Add Admin User</span>
          <img src={errorIcon} alt="error" />
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleCreateAdmin(values)}
        >
          {({ handleSubmit, errors, touched }) => {
            if (
              errors["firstName"] &&
              errors["lastName"] &&
              errors["email"] &&
              errors["phone"]
            ) {
              setHeight(true);
            } else if (
              !errors["firstName"] &&
              !errors["lastName"] &&
              !errors["email"] &&
              !errors["phone"]
            ) {
              setHeight(false);
            }
            return (
              <>
                <form className="card-field-add" onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className="custom-input-add form-control"
                    style={{
                      padding: "1.8rem 0.75rem",
                      marginTop: height ? "-22px" : "0px",
                    }}
                  />

                  {errors["firstName"] && touched["firstName"] && (
                    <span
                      className="text-error"
                      style={{
                        color: "red",
                        marginTop: height ? "-13px" : "-20px",
                        fontSize: "14px",
                        marginBottom: height ? "-16px" : "-28px",
                      }}
                    >
                      {errors["firstName"]}
                    </span>
                  )}

                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className="custom-input-add form-control"
                    style={{
                      padding: "1.8rem 0.75rem",
                      marginTop: height ? "0px" : "13px",
                    }}
                  />

                  {errors["lastName"] && touched["lastName"] && (
                    <span
                      className="text-error "
                      style={{
                        color: "red",
                        marginTop: height ? "-13px" : "-16px",
                        fontSize: "14px",
                        marginBottom: height ? "-16px" : "-28px",
                      }}
                    >
                      {errors["lastName"]}
                    </span>
                  )}
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="custom-input-add form-control"
                    style={{
                      padding: "1.8rem 0.75rem",
                      marginTop: height ? "0px" : "13px",
                    }}
                  />
                  {errors["email"] && touched["email"] && !error && (
                    <span
                      className="text-error"
                      style={{
                        color: "red",
                        marginTop: height ? "-16px" : "-12px",
                        fontSize: "14px",
                        marginBottom: height ? "-16px" : "-28px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  )}
                  {error && error === "Email is already in use" && (
                    <span
                      className="text-error"
                      style={{
                        color: "red",
                        marginTop: height ? "-16px" : "-12px",
                        fontSize: "14px",
                        marginBottom: height ? "-16px" : "-28px",
                      }}
                    >
                      {error}
                    </span>
                  )}

                  <Field
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    className="custom-input-add form-control"
                    style={{
                      padding: "1.8rem 0.75rem",
                      marginTop: height ? "0px" : "13px",
                    }}
                  />

                  {errors["phone"] && touched["phone"] && (
                    <span
                      className="text-error"
                      style={{
                        color: "red",
                        marginTop: height ? "-16px" : "-16px",
                        fontSize: "14px",
                        marginBottom: height ? "5px" : "-10px",
                      }}
                    >
                      {errors["phone"]}
                    </span>
                  )}

                  <div className="card-button-container-add">
                    <Link to="/admin-users" className="links">
                      <button
                        className="custom-button-back-add"
                        onClick={() => navigate("/admin-users")}
                      >
                        Cancel
                      </button>
                    </Link>
                    <button
                      type="submit"
                      className="custom-button-create-add"
                      onClick={() => handleCreateAdmin}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddAdmin;
