import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./CustomTable.scss";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Tab from "../Tabs/Tab";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";

const CustomTable = ({
  columns,
  data,
  context,
  onRowClick,
  pageNumber,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  totalRecords,
  labels,
  showFilterBtn,
  showSearchBar,
  shortTable,
  changeTab,
  tableHeight,
  userFilter,
  tabWidth,
  showPagination,
}) => {
  const navigate = useNavigate();
 
  const [searchValue, setSearchValue] = useState("");
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    onPageChange(1);
  };
  const handleFClick = (event) => {
    event.preventDefault();
    setFilterPanelOpen(!isFilterPanelOpen);
  };

  const dataWithIds = data.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const filteredData = dataWithIds?.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const toggleFilterPanel = () => {
    setShowFilterPanel(!showFilterPanel);
  };

 
  const handleRowClick = (rowData) => {
    if (onRowClick) {
      onRowClick(rowData);
    }

    if (context === "flagitem") {
      navigate(`/flag-items/flag/${rowData._id}`, {
        state: { userData: rowData },
      });
    } else if (context === "user") {
      navigate(`/users/user/${rowData._id}`, { state: { userData: rowData } });
    } else if (context === "admin-user") {
      navigate(`/admin-users/admin-user/${rowData._id}`, {
        state: { userData: rowData },
      });
    } else if (context === "date-event") {
      navigate(`/eventmanager/date-event`, { state: { userData: rowData } });
    } else if (context === "event") {
      navigate(`/events/event`, { state: { userData: rowData } });
    }
  };

  return (
    <Container fluid style={{ width: shortTable ? "200%" : "100%" }}>
      <Row>
        <Col md={12}>
          <div className="table-container">
            <Form className="mb-3">
              <Form.Group as={Row} controlId="search">
                <Col sm={6} md={2}>
                  {showSearchBar ? (
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : null}
                </Col>
                <Col sm={4} md={6}></Col>
              </Form.Group>
            </Form>
            {labels && (
              <Tab labels={labels} changeTab={changeTab} tabWidth={tabWidth} />
            )}
            <div style={{ width: "100%" }}>
              <DataGrid
                style={{
                  height: tableHeight ? "300px" : "679px",
                  width: tableHeight ? "40%" : "100%",
                  border: "none",
                  marginTop: "-70px",
                }}
                rows={filteredData}
                columns={columns.map((column) => ({
                  field: column.id,
                  headerName: column.label,
                  flex: 1,
                  minWidth: 230,
                  renderCell: (params) => (
                    <div
                      style={{ 
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {column.id === "userStatus" ? (
                        <Button variant="contained" className="btn-table">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              letterSpacing: "0.1px",
                              textAlign: "center",
                            }}
                          >
                            {params.value}
                          </span>
                        </Button>
                      ) :  column.id === "summary" ? (
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                          {params.value.length > 20
                            ? `${params.value.substring(0, 20)}...`
                            : params.value}
                        </div>
                          ) : column.format ? (
                        column.format(params.value)
                      ) : (
                        params.value
                      )}
                    </div>
                  ),
                }))}
                components={{
                  Toolbar: () => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {!userFilter ? (
                        <button className="btn-filter">
                          <GridToolbarFilterButton onClick={handleFClick} />
                        </button>
                      ) : (
                        <div style={{ marginTop: "30px" }}></div>
                      )}
                    </div>
                  ),
                }}
                onRowClick={(params) => handleRowClick(params.row)}
                page={pageNumber}
                pageSize={rowsPerPage}
                rowCount={totalRecords}
                onPageChange={onPageChange}
                pageSizeOptions={[10, 20, 30, 50]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                      /* page: 0 // default value will be used if not passed */
                    },
                  },
                }}
                disableColumnMenu 
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row> */}
      {/* </Row> */}
    </Container>
  );
};

export default CustomTable;
