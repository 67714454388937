import React, { useState, useRef, useEffect } from "react";
import "./passwordReset.scss";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PasswordVerify = () => {
  const [redirect, setRedirect] = useState(false);
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleVerificationCodeChange = (e, index) => {
    const updatedVerificationCode = [...verificationCode];
    const inputValue = e.target.value;
  
    if (inputValue !== "") {
      updatedVerificationCode[index] = inputValue;
  
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      updatedVerificationCode[index] = "";
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  
    setVerificationCode(updatedVerificationCode);
  };
  

  const handleVerifyCode = async () => {
    const code = verificationCode.join("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/verify`,
        {
          verificationCode: code,
        }
      );

      console.log(response);
      localStorage.setItem("verificationCode", code);
      setVerificationStatus(true);
      if (response.data) {
        window.location.href = "/reset-password";
      }
      setRedirect(true);
    } catch (error) {
      setVerificationStatus(false);
      setRedirect(false);
      if (error.response && error.response.data && error.response.data.error) {
        setVerificationError(error.response.data.error);
      } else {
        setVerificationError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (verificationStatus && redirect) {
      navigate("/reset-password");
    }
  }, [verificationStatus, redirect, navigate]);

  return (
    <div className="wrapper-all">
      <div className="first-half">
        <div className="logo">
          <div className="avatar-container">
            <span className="custom-avatar">
            <img src="IMG-9805.JPG" alt="logo" style={{width: "64px"}}/>
            </span>
          </div>
        </div>

        <div className="verify-title">
          <p>Verification Code</p>
        </div>

        <div className="verify-title-body">Check your email for your verification code.</div>
      </div>
      <div className="verification-code-container">
        <div className="key-icon">
          <KeyIcon />
        </div>
        {verificationCode.map((digit, index) => (
          <div className="digit-input" key={index}>
            <input
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleVerificationCodeChange(e, index)}
              ref={(input) => (inputRefs.current[index] = input)}
            />
          </div>
        ))}
      </div>
      {verificationError && <p className="error-message-reset">{verificationError}</p>}
      <div className="button-container-password">
        {/* <Link to="/password-reset-request" className="links">
          <button className="back-button">Back</button>
        </Link> */}

        <button className="continue-button-verify" onClick={handleVerifyCode}>
          Continue
        </button>
      </div>

      <div className="new-verify-bottom">
      <div className="button help" onClick={() => navigate("/support")}>
          Help
        </div>
        <div
          className="button privacy"
          onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
        >
          Privacy
        </div>
        <div
          className="button terms"
          onClick={() =>
            window.open("https://www.uonapp.com/user-agreement-for-uon")
          }
        >
          Terms
        </div>
      </div>
    </div>
  );
};

export default PasswordVerify;
