import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "./Table.scss";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import NewSystemMessage from "../../pages/NewSystemMessage/NewSystemMessage";
import Tab from "../Tabs/Tab";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";

const Table = ({
	columns,
	data,
	context,
	onRowClick,
	pageNumber,
	rowsPerPage,
	onPageChange,
	onRowsPerPageChange,
	totalRecords,
	labels,
	showFilterBtn,
	showSearchBar,
	shortTable,
	changeTab,
	tableHeight,
	userFilter,
	tabWidth,
	tabWidth2,
	showPagination,
	selectedTab,
}) => {
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState("");
	const [isFilterPanelOpen, setFilterPanelOpen] = useState(false);
	const [isDataGridVisible, setDataGridVisible] = useState(false);

	const handleSearchChange = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		onPageChange(1);
	};
	const handleFClick = (event) => {
		event.preventDefault();
		setFilterPanelOpen(!isFilterPanelOpen);
	};

	const dataWithIds = data.map((row, index) => ({
		...row,
		id: index + 1,
	}));

	const filteredData = dataWithIds?.filter((row) =>
		Object.values(row).some(
			(value) =>
				value &&
				value.toString().toLowerCase().includes(searchValue.toLowerCase())
		)
	);

	useEffect(() => {
		if (labels[0] === "New Message" && selectedTab === 0) {
			setDataGridVisible(true);
		} else {
			setDataGridVisible(false);
		}
	}, [selectedTab]);

	console.log(isDataGridVisible, "value----");
	console.log(selectedTab,"tabs-----")
	const handleRowClick = (rowData) => {
		if (onRowClick) {
			onRowClick(rowData);
		}
		if (context === "system_message") {
			navigate(`/system_message/message`, {
				state: { userData: rowData, previousTab: selectedTab },
			});
		}
	};

	return (
		<Container fluid style={{ width: shortTable ? "200%" : "100%" }}>
			<Row>
				<Col md={12}>
					<div className="table-container">
						<Form className="mb-3">
							<Form.Group as={Row} controlId="search">
								<Col sm={6} md={2}>
									{!isDataGridVisible &&
										selectedTab !== "Trigger Templates" &&
										selectedTab !== 3 && (
											<TextField
												fullWidth
												size="small"
												type="text"
												placeholder="Search"
												value={searchValue}
												onChange={handleSearchChange}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<SearchIcon />
														</InputAdornment>
													),
												}}
											/>
										)}
								</Col>
								<Col sm={4} md={6}></Col>
							</Form.Group>
						</Form>
						{labels && (
							<Tab
								labels={labels}
								changeTab={changeTab}
								tabWidth={tabWidth}
								tabWidth2={tabWidth2}
							/>
						)}
						{!isDataGridVisible ? (
							<div style={{ width: "100%" }}>
								<DataGrid
									style={{
										height: tableHeight ? "300px" : "679px",
										width: tableHeight ? "40%" : "100%",
										border: "none",
										marginTop: selectedTab === 3 ? "-15px" : "-70px",
									}}
									className="colunm-header"
									rows={filteredData}
									columns={columns.map((column) => ({
										field: column.id,
										headerName: column.label,

										flex: 1,
										minWidth: column.id === "messageContent" ? 400 : 230,
										renderCell: (params) => (
											<div
												style={{
													cursor: "pointer",
													display: "flex",
													alignItems: "center",
												}}
											>
												{column.id === "messageContent" ? (
													<div
														style={{
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
														title={params.value}
													>
														{params.value.length > 50
															? `${params.value.substring(0, 50)}...`
															: params.value}
													</div>
												) : column.id === "time" ? (
													<div>{params.value} PT</div>
												) : column.format ? (
													column.format(params.value)
												) : (
													params.value
												)}
											</div>
										),
									}))}
									components={{
										Toolbar: () => (
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "end",
													marginTop: "10px",
													marginRight: "10px",
												}}
											>
												{!userFilter &&
												selectedTab !== "Trigger Templates" &&
												selectedTab !== 3 ? (
													<button className="btn-filter">
														<GridToolbarFilterButton onClick={handleFClick} />
													</button>
												) : (
													<div style={{ marginTop: "30px" }}></div>
												)}
											</div>
										),
									}}
									onRowClick={(params) => handleRowClick(params.row)}
									page={pageNumber}
									pageSize={rowsPerPage}
									rowCount={totalRecords}
									onPageChange={onPageChange}
									pageSizeOptions={[10, 20, 30, 50]}
									initialState={{
										pagination: {
											paginationModel: {
												pageSize: 10,
											},
										},
									}}
									disableColumnMenu
								/>
							</div>
						) : (
							<NewSystemMessage />
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Table;
