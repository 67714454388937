import React, { useState } from "react";
const Tabcontext = React.createContext({})


const TabProvider = ({children})=>{
    const [tabValue, setTabValue] = useState("");
    return(
        <Tabcontext.Provider
        value={{
            tabValue,
            setTabValue
        }}
        >{children}</Tabcontext.Provider>
    )
}


export {Tabcontext,TabProvider}
