import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
	Error as ErrorIcon,
	Settings as SettingsIcon,
} from "@mui/icons-material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import "./sidebar.scss";
import { useFlaggedItemsCount } from "../../Context/FlaggedItemsContext";
import PeopleIcon from "@mui/icons-material/People";
import profileIcon from "../../assets/images/profile.svg";
import GroupsIcon from "@mui/icons-material/Groups";
import { Tabcontext } from "../../Context/TabContext";
import { useContext } from "react";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

const Sidebar = () => {
	const { totalFlaggedItems } = useFlaggedItemsCount();
	const { setTabValue } = useContext(Tabcontext);

	const handleClick = () => {
		setTabValue(0);
	};

	const handleSystemMessageClick = () => {
		setTabValue(1);
	};

	return (
		<div className="sidebar" id="customSidebar">
			<ul className="nav flex-column">
				<NavLink
					to="/flag-items"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleClick}
					>
						<span className="sidebar-icon">
							<ErrorIcon />
						</span>
						Flagged items{" "}
						<span style={{ marginLeft: "25px" }}>{totalFlaggedItems}+</span>
					</Button>
				</NavLink>
				<NavLink
					to="/users"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleClick}
					>
						<span className="sidebar-icon">
							<img src={profileIcon} alt="profile" />
						</span>
						Users
					</Button>
				</NavLink>

				{/* <NavLink
          to="/business-users"
          activeclassname="active"
          exact={true}
          className="sidebar-link"
        >
          <Button className="sidebar-button" variant="light">
            <span className="sidebar-icon">
              <AccountCircleIcon />
            </span>
            Business Users
          </Button>
        </NavLink> */}
				<NavLink
					to="/admin-users"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleClick}
					>
						<span className="sidebar-icon">
							<PeopleIcon />
						</span>
						Admin Users
					</Button>
				</NavLink>
				<NavLink
					to="/eventmanager"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleClick}
					>
						<span className="sidebar-icon">
							<GroupsIcon />
						</span>
						Dating Events
					</Button>
				</NavLink>
				<NavLink
					to="/events"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleClick}
					>
						<span className="sidebar-icon">
							<InsertInvitationIcon />
						</span>
						Events
					</Button>
				</NavLink>
				<hr className="sidebar-line" />
				<NavLink
					to="/setting"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button className="sidebar-button" variant="light">
						<span className="sidebar-icon">
							<SettingsIcon />
						</span>
						System Settings
					</Button>
				</NavLink>
				<NavLink
					to="/system_message"
					activeClassName="active"
					exact={true}
					className="sidebar-link"
				>
					<Button
						className="sidebar-button"
						variant="light"
						onClick={handleSystemMessageClick}
					>
						<span className="sidebar-icon">
							<MessageOutlinedIcon />
						</span>
						System Messages
					</Button>
				</NavLink>
				<hr className="sidebar-line" />
			</ul>
		</div>
	);
};

export default Sidebar;
