import React, { useState, useEffect } from "react";
import "./DatingEvent.scss";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import CustomTable from "../../components/table/CustomTable";
import EditAdmin from "../EditAdmin/EditAdmin";
import AddAdmin from "../AddAdmin/AddAdmin";
import UpdatePassword from "../UpdatePassword/UpdatePassword";
import { Tabcontext } from "../../Context/TabContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";


const columns = [
  { id: "eventName", label: "Event Name", minWidth: 170 },
  { id: "createdAt", label: "Created At", minWidth: 170 },
  { id: "location", label: "Location", minWidth: 100 },
  { id: "radius", label: "Radius", minWidth: 100 },
  { id: "time", label: "Time", minWidth: 100 },
  { id: "participant", label: "Participants", minWidth: 100 },
  { id: "matchUser", label: "Matched Users", minWidth: 100 },
];

const dummyData = [
  {
    eventName: "Test Event",
    createdAt: "12/04/23,12:30 PM PT",
    location: "New York, NY",
    radius: "10 miles",
    time: "5:30 PM PT",
    participant: 50,
    matchUser: 50
  },
  {
    eventName: "Test Event",
    createdAt: "12/04/23,12:30 PM PT",
    location: "Los Angeles, CA",
    radius: "15 miles",
    time: "5:30 PM PT",
    participant: 30,
    matchUser: 50
  },
  {
    eventName: "Test Event",
    createdAt: "12/04/23,12:30 PM PT",
    location: "Chicago, IL",
    radius: "12 miles",
    time: "5:30 PM PT",
    participant: 40,
    matchUser: 50
  }
];

const DatingEvent = () => {
  const { tabValue } = useContext(Tabcontext);
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showUpdatePassword, setUpdatePassword] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFilterBtn] = useState(true);
  const [showSearchBar] = useState(true);
  const [showPagination] = useState(true);
  const [showModifyBtn] = useState(true);
  const [showCreateEventBtn] = useState(true);
  const navigate = useNavigate();

  const handleCreateEventButtonClick = () => {
    navigate("/eventmanager/create-event");
  };
  const handleModifyClick = () => {
    navigate("/eventmanager/modify-question-bank");
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    setData(dummyData)
  }, [authToken, pageNumber, rowsPerPage]);


  useEffect(() => {
    if (authToken && tabValue === 0) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users?page=${pageNumber}&per_page=${rowsPerPage}&userType=Admin&userStatus=Active`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          const modifiedData = response.data.data.users.map((user) => ({
            ...user,
            name: `${user?.firstName} ${user?.lastName}`,
          }));
          setData(modifiedData);
          setTotalRecords(response?.data?.results);
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  }, [authToken, pageNumber, rowsPerPage, tabValue]);


  useEffect(() => {
    if (authToken && tabValue === 1) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users?page=${pageNumber}&per_page=${rowsPerPage}&userType=Admin&userStatus=InActive`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          const modifiedData = response.data.data.users.map((user) => ({
            ...user,
            name: `${user?.firstName} ${user?.lastName}`,
          }));
          setData(modifiedData);
          setTotalRecords(response?.data?.results);
        })
        .catch((error) => {
          console.error("Error fetching users data:", error);
        });
    }
  }, [authToken, pageNumber, rowsPerPage, tabValue]);

  const handleRowSelect = (rowData) => {
    setSelectedRow(rowData);
  };

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar
              header="Event Manager"
              showModifyButton={showModifyBtn}
              showCreateEventButton={showCreateEventBtn}
              onButtonClick={handleCreateEventButtonClick}
              onModifyButtonClick={handleModifyClick}

            />
          </div>

          <div className="table-container">
            <CustomTable
              columns={columns}
              data={dummyData}
              context="date-event"
              onRowClick={handleRowSelect}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
              labels={['Active', 'Inactive','Ended']}
              showFilterBtn={showFilterBtn}
              showSearchBar={showSearchBar}
              showPagination={showPagination}
            />

            {selectedRow && (
              <div className="blur-background">
                <EditAdmin
                  rowData={selectedRow}
                />
              </div>
            )}
            {showAddAdmin && (
              <div className="blur-background">
                <AddAdmin setShowAddAdmin={setShowAddAdmin} />
              </div>
            )}
            {showUpdatePassword && (
              <div className="blur-background">
                <UpdatePassword setUpdatePassword={setUpdatePassword} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatingEvent;
