import React, { useState, useEffect } from "react";
import "./AddEvent.scss";
import { useLocation } from "react-router-dom";
import { Box, Typography, FormControl, InputLabel, TextField } from '@mui/material';
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TopBar from "../../components/topbar/topbar";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const AddEvent = () => {
  const [participant, setParticipant] = React.useState('');
  const [status, setStatus] = useState("");

  const handleChange = (event) => {
    setParticipant(event.target.value);
  };

  const dummyRows = [
  {
    firstName: "10223",
    email: "Admin",
    __v: 123,
    date: "2023-08-17",
  },
  {
    firstName: "12345",
    email: "User",
    __v: 456,
    date: "2023-08-16",
  },
];
  const [editFields, setEditFields] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [data, setData] = useState([]);
  const location = useLocation();

  console.log(data);
  

    useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData)
      // const dateObject = new Date(userData.lastActive);
      // const formattedDate = dateObject.toLocaleString('en-US', {
      //   month: 'short',
      //   day: 'numeric',
      //   year: 'numeric',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZone: 'UTC'
      // });
    
      setFormData({
        eventName: userData?.eventName,
        location: userData?.location,
        radius: userData?.radius,
        time: userData?.time,
        participant: userData?.participant,
        status: "Active"
      });
    }
    setData(dummyRows);
  }, [location.state]);


  const [formData, setFormData] = useState({
    eventName: "",
    location: "",
    radius: "",
    time: "",
    participant: "",
    status: "Active",
  });
  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    status: false,
    userType: false,
    lastActive: false,
  });

  console.log(editableFields);

  const handleEditClick = (event) => {
    navigate("/create-event");
    //  setEditFields(!editFields);
    //  if(editFields){
    //       event.preventDefault();
    // const userId = location.state.userData._id;

    // axios
    //   .patch(
    //     `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
    //     {
    //       firstName: formData?.firstname,
    //       lastName: formData?.lastname,
    //       email: formData?.email,
    //       phone: formData?.phone,
    //       userStatus: formData?.status,
    //       userType: formData?.userType,
    //       lastActive: formData?.lastActive,
    //     },
    //     {
    //       headers: {
    //         Authorization: `${authToken}`,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log("User information updated:", response.data);
    //     setEditableFields({
    //       name: false,
    //       email: false,
    //       phone: false,
    //       status: false,
    //       userType: false,
    //       lastActive: false,
    //     });
    //     navigate("/users");
    //   })
    //   .catch((error) => {
    //     console.error("Error updating admin information:", error);
    //   });
    //  }
  }
  // const myVariant = editFields ? undefined : "standard";
  // const inputLabelStyle = {
  //   '&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled':{
  //     color: 'black'
  //   }
  // }
  // const inputStyles = {
  //   "& .MuiInputBase-input.Mui-disabled": {
  //     WebkitTextFillColor: "#000000",
  //   },
  //  }
  // const formStyles = { marginTop: 2 }

  // const [selectedUserType, setSelectedUserType] = useState(formData?.userType);
  // const [selectedUserStatus, setSelectedUserStatus] = useState(formData?.status);

  const handleCancelClick = () => {
    navigate("/eventmanager")
  }

  return (
    <>
     <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
      <div className="main-card">
      <div className="topbar-container">
              <TopBar
                leftIcon={
                  <Link to="/events" className="links">
                    <ArrowBackIcon className="backIconStyling"/>
                  </Link>
                }
                header={`Create Event`}
              />
            </div>
        <div className="card-wrapper" style={{width: "92%"}}>
          <Container fluid>
          <Box className='card-header'>
            <Typography className="card-header-about">
              Create Event
            </Typography>
            <div>
            <button 
              variant={'outlined'}
              className="cancel-button"
              onClick={handleCancelClick}>
              Cancel
            </button>
            <span style={{marginLeft: "20px"}}>
            <button 
              variant='contained'
              className="large-save-button"
              onClick={handleEditClick}>
              Save
            </button>
            </span>
            </div>
          </Box>
          <Box className='form' display='flex' flexDirection='column' pt='24px'>
          <div className="inputStyling">
          <TextField
          label="Event Name"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '50ch' }}
        />
        </div>
        <div className="inputStyling">
         <TextField
          label="Location"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '50ch' }}
        />
           </div>
           <div className="inputStyling">
         <TextField
          label="Radius"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '50ch' }}
        />
           </div>
           <div className="inputStyling">
         <TextField
          label="Time"
          id="outlined-start-adornment"
          sx={{ m: 1, width: '50ch' }}
        />
           </div>
           <div className="inputStyling">
           <FormControl sx={{ m: 1, minWidth: 450 }}>
        <InputLabel id="demo-simple-select-helper-label">Participant Cap</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={participant}
          label="Participant Cap"
          onChange={(e) => {
            e.preventDefault();
            setParticipant(e.target.value);
          }}
        >
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={70}>70</MenuItem>
          <MenuItem value={80}>80</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
           </div>
           <div className="inputStyling">
           <FormControl sx={{ m: 1, minWidth: 450 }}>
        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={status}
          label="Status"
          onChange={(e) => {
            e.preventDefault();
            setStatus(e.target.value);
          }}
        >
          <MenuItem value={10}>Active</MenuItem>
          <MenuItem value={20}>InActive</MenuItem>
        </Select>
      </FormControl>
           </div>
          </Box>
          </Container>

        </div>
        
      </div>
      </div>
      </div>
    </>
  );
};


export default AddEvent;
