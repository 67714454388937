import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
import Table from "../../components/Table/Table";
import "./SystemMessage.scss";
import { Tabcontext } from "../../Context/TabContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

const SystemMessage = () => {
  const { tabValue } = useContext(Tabcontext);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [authToken, setAuthToken] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showFilterBtn] = useState(true);
  const [showSearchBar] = useState(true);
  const [showPagination] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [columns, setColumns] = useState([
    { id: "subject", label: "Subject", minWidth: 100 },
    { id: "messageContent", label: "Message Content", minWidth: 200 },
    { id: "recipient", label: "Recipient", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    { id: "time", label: "Time", minWidth: 100 },
  ]);
  const [tabWidth2] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const previousTabFromState = location.state && location.state.previousTab;
    const previousTabFromLocalStorage = localStorage.getItem("selectedTab");
    const previousTab =
      previousTabFromState || previousTabFromLocalStorage || 0;
    setSelectedTab(previousTab);
  }, [location.state]);

  useEffect(() => {
    if (tabValue) {
      fetchData(tabValue);
    } else {
      fetchData(selectedTab);
    }
  }, [authToken, pageNumber, rowsPerPage, selectedTab, tabValue]);

  const fetchData = async (tabIndex) => {
    if (authToken) {
      const tabNames = [
        "New Message",
        "Scheduled Messages",
        "Messages Sent",
        "Trigger Templates",
      ];
      const tabName = tabNames[tabIndex];

      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }/api/v1/system_message?messageStatus=${tabName ? tabName : ""}`,
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          const systemMessages = response?.data?.data?.systemMessages || [];
          const modifiedData = systemMessages.map((message) => {
            const data = {
              _id: message?._id,
              subject: message?.subject,
              messageContent: message?.messageContent,
              recipient: message?.recipient,
              date: message?.date,
              time: message?.time,
              messageStatus: tabName,
            };
            if (tabName === "Trigger Templates") {
              data.lastUpdated = message?.lastUpdated || "";
              data.location = message?.location || "";
              setColumns([
                { id: "subject", label: "Template Name", minWidth: 100 },
                {
                  id: "messageContent",
                  label: "Message Content",
                  minWidth: 170,
                },
                { id: "recipient", label: "Recipient", minWidth: 100 },
                {
                  id: "lastUpdated",
                  label: "Last Updated",
                  minWidth: 100,
                  format: (value) => {
                    const inputDate = new Date(value);

                    const options = {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "America/Los_Angeles",
                    };

                    const formattedDate = inputDate.toLocaleDateString(
                      "en-US",
                      options
                    );
                    return formattedDate + " PT";
                  },
                },
                { id: "location", label: "Last Updated By", minWidth: 170 },
              ]);
            } else {
              setColumns([
                { id: "subject", label: "Subject", minWidth: 100 },
                {
                  id: "messageContent",
                  label: "Message Content",
                  minWidth: 170,
                },
                { id: "recipient", label: "Recipient", minWidth: 100 },
                // { id: "date", label: "Date", minWidth: 100 },
                {
                  id: "date",
                  label: "Date",
                  minWidth: 100,
                  format: (value) => {
                    const inputDate = new Date(value);
                    const options = {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour12: true,
                      timeZone: "America/Los_Angeles",
                    };
                    return inputDate.toLocaleDateString("en-US", options);
                  },
                },
                { id: "time", label: "Time", minWidth: 100 },
              ]);
            }
            return data;
          });
          setTotalRecords(response?.data?.results);
          setData(modifiedData);
        })
        .catch((error) => {
          console.error("Error fetching system messages data:", error);
        });
    }
  };

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-container">
        <Sidebar />
        <div className="main-content">
          <div className="topbar-container">
            <TopBar header="System Messages" />
          </div>

          <div className="table-container">
            <Table
              columns={columns}
              data={data}
              context="system_message"
              labels={[
                "New Message",
                "Scheduled Messages",
                "Messages Sent",
                "Trigger Templates",
              ]}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              totalRecords={totalRecords}
              showFilterBtn={showFilterBtn}
              showSearchBar={showSearchBar}
              showPagination={showPagination}
              changeTab={setSelectedTab}
              selectedTab={selectedTab}
              hideColumns={["messageStatus"]}
              tabWidth2={tabWidth2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemMessage;
