import React, { useState, useEffect } from "react";
import axios from "axios";

const HealthCheck = () => {
  const [serverStatus, setServerStatus] = useState("");

  useEffect(() => {
    const fetchServerStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/health`
        );
        setServerStatus(response.data);
      } catch (error) {
        console.error("Error fetching server status:", error);
        setServerStatus("Error");
      }
    };

    fetchServerStatus();
  }, []);

  return (
    <div>
      <h3>Client OK</h3>
      <p>Server Status: {serverStatus}</p>
    </div>
  );
};

export default HealthCheck;
