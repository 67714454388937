import React, { useState } from "react";
import "./Support.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [resetError, setResetError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/forgot_password_admin`,
        {
          email: email,
        }
      );
      if (response.data) {
        window.location.href = "/password-reset-request";
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setResetError(error.response.data.error);
      } else {
        setResetError(error.response.data.message);
      }
    }
  };
  const handleContinueAndReset = () => {
    if (!email) {
      setResetError("Please Enter Email");
      return;
    }
    handleResetPassword();
  };

  return (
    <div className="container">
      <div className="support-wrapper-all">
        <div className="first-half">
          <div className="logo">
            <div className="avatar-container">
              <p style={{ fontSize: "32px" }}>Uon</p>
            </div>
          </div>
          <div className="support-password-title">
            <p>Request Support</p>
          </div>

          <div className="support-title-body">
            <p>Have a question or problem? Please let us know!</p>
          </div>
        </div>
        <div className="support-field">
          <input
            type="text"
            name="email"
            id="userName"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="support-field">
          <input
            type="text"
            name="Subject"
            id="support"
            placeholder="Support"
            value={subject}
            onChange={handleSubjectChange}
          />
        </div>
        <div className="message-field">
          <input
            type="text"
            name="Message"
            id="message"
            placeholder="Message"
            value={message}
            onChange={handleMessageChange}
          />
        </div>
        <div className="button-container-support">
          <button className="backButton" onClick={() => navigate(-1)}>
            Back
          </button>
          <button className="support-button" onClick={handleContinueAndReset}>
            Request Support
          </button>
        </div>
        {resetError && <p className="error-message-reset">{resetError}</p>}

        <div className="new-bottom">
          <div className="button help" onClick={() => navigate("/support")}>
            Help
          </div>
          <div
            className="button privacy"
            onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
          >
            Privacy
          </div>
          <div
            className="button terms"
            onClick={() =>
              window.open("https://www.uonapp.com/user-agreement-for-uon")
            }
          >
            Terms
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
