
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoutes = (props) => {
  const {Component} = props
 let token = localStorage.getItem("authToken")

 return (
  <>
  <div>
    {
    token?(<Component/>) : <Navigate to="/sign-in" />
    }
  </div>

  </>
 )
};

export default PrivateRoutes;
