import React, { useState } from "react";
import "./passwordReset.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const { state } = useLocation();
  const isEmailSubmitted = state?.isEmailSubmitted;
  const prevEmail = state?.email;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/forgot_password_admin`,
        {
          email: email,
        }
      );
      if (response.data) {
        navigate("/password-reset-request");
      }
    } catch (error) {}
  };
  const handleContinueAndReset = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]+\w+$/;
    if (!email) {
      setResetError("Email is required to login");
    } else if (!emailRegex.test(email)) {
      setResetError("Please check your email and try again");
    } else {
      setResetError("");
    }
    handleResetPassword();
  };

  const backButtonClick = () => {
    navigate("/sign-in", {
      state: {
        email: prevEmail,
        isEmailSubmitted: isEmailSubmitted,
      },
    });
  };

  return (
    <div className="container">
      <div className="wrapper-all">
        <div className="first-half">
          <div className="logo">
            <div className="avatar-container">
              <span className="custom-avatar">
                <img src="IMG-9805.JPG" alt="logo" style={{ width: "64px" }} />
              </span>
            </div>
          </div>
          <div className="password-title">
            <p>Password Reset</p>
          </div>

          <div className="title-body">
            <p>Enter your email to reset your password.</p>
          </div>
        </div>
        <div className="field">
          <MailOutlineIcon />
          <input
            type="text"
            name="email"
            id="userName"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        {resetError && <div className="error-message-reset">{resetError}</div>}
        <div className="button-container-reset">
          <button className="back-button" onClick={backButtonClick}>
            Back
          </button>

          <button className="continue-button" onClick={handleContinueAndReset}>
            Continue
          </button>
        </div>

        <div className="new-bottom">
          <div className="button help" onClick={() => navigate("/support")}>
            Help
          </div>
          <div
            className="button privacy"
            onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
          >
            Privacy
          </div>
          <div
            className="button terms"
            onClick={() =>
              window.open("https://www.uonapp.com/user-agreement-for-uon")
            }
          >
            Terms
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
