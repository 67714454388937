import React, { useState } from "react";
import "./ResetPassword.scss";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [resetError, setResetError] = useState("");


  const handleInputPassword = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    if (password.trim() === "") {
      setResetError("Password cannot be empty");
      return; 
    }
    try {
      const verificationCode = localStorage.getItem("verificationCode");
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/update_admin_password`,
        {
          verificationCode: verificationCode,
          newPassword: password,
        }
      );

      if (response.data) {
        window.location.href = "/sign-in";
      }
    } catch (error) {


      if (error.response && error.response.data && error.response.data.error) {
        setResetError(error.response.data.error);
      } else {
        setResetError("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <>
      <div class="wrapper-all">
        <div className="first-half-reset-password">
          <div class="logo" style={{marginTop: "20px"}}>
            <div className="avatar-container">
              <span className="custom-avatar">
                <img src="IMG-9805.JPG" alt="logo" style={{ width: "64px" }} />
              </span>
            </div>
          </div>
          <div className="password-reset-title">
            <p style={{ marginBottom: "-20px", paddingTop: "13px" }}>Set Password</p>
          </div>
          <div class="title-body">
            <p>Pick something creative yet memorable</p>
          </div>
        </div>
        <div class="field" style={{marginTop: "35px"}}>
          <KeyIcon />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            onChange={handleInputPassword}
          />
          <div className="material-icon" onClick={togglePasswordVisibility}>
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
        </div>
        <div className="button-container-password">
          {/* <Link to="/password-verify" className="links">
            <button className="back-button">Back</button>
          </Link> */}
    {resetError && <div className="error-reset-password-message">{resetError}</div>}
          <button className="continue-button" onClick={handleUpdatePassword}>
            Continue
          </button>
        </div>
       

        <div className="new-reset-bottom">
        <div className="button help" onClick={() => navigate("/support")}>
          Help
        </div>
        <div
          className="button privacy"
          onClick={() => window.open("https://www.uonapp.com/privacy-policy")}
        >
          Privacy
        </div>
        <div
          className="button terms"
          onClick={() =>
            window.open("https://www.uonapp.com/user-agreement-for-uon")
          }
        >
          Terms
        </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
