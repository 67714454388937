import React, { useState, useEffect } from "react";
import "./SingleEvent.scss";
import { useLocation } from "react-router-dom";
import { Button, OutlinedInput, Box, Typography, FormControl, Input, InputLabel } from '@mui/material';
import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TopBar from "../../components/topbar/topbar";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SingleEvent = () => {
  const dummyRows = [
    {
      firstName: "John Doe",
      email: "Admin",
      __v: 123,
      date: "2023-08-17",
    },
    {
      firstName: "Jane Smith",
      email: "User",
      __v: 456,
      date: "2023-08-16",
    },
  ];
  const [editFields, setEditFields] = useState(false);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [data, setData] = useState([]);
  // const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  console.log(data);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }

    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      console.log(userData)
      // const dateObject = new Date(userData.lastActive);
      // const formattedDate = dateObject.toLocaleString('en-US', {
      //   month: 'short',
      //   day: 'numeric',
      //   year: 'numeric',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   timeZone: 'UTC'
      // });

      setFormData({
        eventName: userData.eventName,
        location: userData.location,
        radius: userData.radius,
        time: userData.time,
        participant: userData.participant,
      });
    }
    setData(dummyRows);
  }, [location.state]);

  // const handleUserTypeChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     userType: event.target.value,
  //   });
  // };


  const CustomIcon = () => {
    return <ArrowDropDownIcon style={{marginRight: "10px"}} />;  // Replace with your desired icon component
  };

  const [formData, setFormData] = useState({
    eventName: "",
    location: "",
    radius: "",
    time: "",
    participant: "",
  });
  const [editableFields, setEditableFields] = useState({
    name: false,
    email: false,
    phone: false,
    status: false,
    userType: false,
    lastActive: false,
  });

  console.log(editableFields);

  const handleEditClick = (event) => {
    setEditFields(!editFields);
    if (editFields) {
      event.preventDefault();
      const userId = location.state.userData._id;

      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/users/${userId}/update`,
          {
            firstName: formData?.firstname,
            lastName: formData?.lastname,
            email: formData?.email,
            phone: formData?.phone,
            userStatus: formData?.status,
            userType: formData?.userType,
            lastActive: formData?.lastActive,
          },
          {
            headers: {
              Authorization: `${authToken}`,
            },
          }
        )
        .then((response) => {
          console.log("User information updated:", response.data);
          setEditableFields({
            name: false,
            email: false,
            phone: false,
            status: false,
            userType: false,
            lastActive: false,
          });
          navigate("/admin-users");
        })
        .catch((error) => {
          console.error("Error updating admin information:", error);
        });
    }
  }
  const myVariant = editFields ? undefined : "standard";
  const inputLabelStyle = {
    '&.MuiFormLabel-root.MuiInputLabel-root.Mui-disabled': {
      color: 'black'
    }
  }
  const inputStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    },
  }
  const formStyles = { marginTop: 2 }


  useEffect(() => {
    if (location.state && location.state.userData) {
      const userData = location.state.userData;
      const { fullName = '' } = userData;
      console.log(fullName)
    }
  }, [location.state]);

  const [selectedUserType, setSelectedUserType] = useState(formData?.userType);

  return (
    <>
      <div className="home-container">
        <Navbar />
        <div className="content-container">
          <Sidebar />
          <div className="main-card">
            <div className="topbar-container">
              <TopBar
                leftIcon={
                  <Link to="/events" className="links">
                    <ArrowBackIcon className="backIconStyling"/>
                  </Link>
                }
                header={`Event`}
              />
            </div>
            <div className="card-wrapper">
              <Container fluid>
                <Box className='card-header'>
                  <Typography className="card-header-about">
                    About
                  </Typography>
                  <Button
                    variant={editFields ? 'contained' : 'outlined'}
                    className={editFields ? 'save-button' : 'edit-button'}
                    onClick={handleEditClick}>
                    {editFields ? 'Save' : 'Edit'}
                  </Button>
                </Box>
                <Box className='form' display='flex' flexDirection='column' pt='24px'>
            <FormControl
              variant={myVariant} 
              disabled = { editFields ? false: true }
              sx={formStyles}
            >
              <InputLabel
                htmlFor="component-outlined" 
                sx={inputLabelStyle}
              >
                 Event Name
              </InputLabel>
              {
                !editFields && <Input 
                  sx={inputStyles} 
                  value={formData?.eventName}
                  disableUnderline 
                />
              }
              {
                editFields && <OutlinedInput
                  id="component-outlined"
                  defaultValue={formData?.eventName}
                  label="First Name"
                  onChange={(e) => {
                    // Update the formData when the input value changes
                    setFormData({
                      ...formData,
                      firstname: e.target.value,
                    });
                  }}
                />
              }
            </FormControl>

            <FormControl
              variant={myVariant} 
              disabled = { editFields ? false: true }
              sx={formStyles}

            >
              <InputLabel
                htmlFor="component-outlined" 
                sx={inputLabelStyle}
              >
                Location
              </InputLabel>
              { 
                !editFields && <Input 
                  sx={inputStyles} 
                  value={formData?.location} 
                  disableUnderline 
                />
              }
              {
                editFields && <OutlinedInput
                  id="component-outlined"
                  defaultValue={formData?.location}
                  label="Username"
                  onChange={(e) => {
                    // Update the formData when the input value changes
                    setFormData({
                      ...formData,
                      lastname: e.target.value,
                    });
                  }}
                />
              }
            </FormControl>

            <FormControl
              variant={myVariant} 
              disabled = { editFields ? false: true }
              sx={formStyles}

            >
              <InputLabel
                htmlFor="component-outlined" 
                sx={inputLabelStyle}
              >
                Radius
              </InputLabel>
              { 
                !editFields && <Input 
                  sx={inputStyles} 
                  value={formData?.radius} 
                  disableUnderline 
                />
              }
              {
                editFields && <OutlinedInput
                  id="component-outlined"
                  defaultValue={formData?.radius}
                  label="Email"
                  onChange={(e) => {
                    // Update the formData when the input value changes
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              }
            </FormControl>

            <FormControl
              variant={myVariant} 
              disabled = { editFields ? false: true }
              sx={formStyles}

            >
              <InputLabel
                htmlFor="component-outlined" 
                sx={inputLabelStyle}
              >
                Time
              </InputLabel>
              { 
                !editFields && <Input 
                  sx={inputStyles} 
                  value={formData?.time} 
                  disableUnderline 
                />
              }
              {
                editFields && <OutlinedInput
                  id="component-outlined"
                  defaultValue={formData?.time}
                  label="Last Active"
                  onChange={(e) => {
                    // Update the formData when the input value changes
                    setFormData({
                      ...formData,
                      lastActive: e.target.value,
                    });
                  }}
                />
              }
            </FormControl>
            <FormControl variant={myVariant} disabled={!editFields} sx={formStyles}>
      <InputLabel htmlFor="component-outlined" sx={inputLabelStyle}>
        Participant
      </InputLabel>
      {!editFields ? (
        <Input sx={inputStyles} value={formData?.participant} disableUnderline />
      ) : (
        <Select
          id="component-outlined"
          value={formData?.participant}
          onChange={(e) => {
            setFormData({
              ...formData,
              participant: e.target.value,
            });
          }}
          label="Participant"
          IconComponent={CustomIcon} 
        >
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={70}>70</MenuItem>
          <MenuItem value={80}>80</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      )}
    </FormControl>
            
          </Box>
              </Container>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default SingleEvent;