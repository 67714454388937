import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Tabcontext } from "../../Context/TabContext";
import { useContext } from "react";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs(props) {
	const { tabValue, setTabValue } = useContext(Tabcontext);
	const [value, setValue] = React.useState(0);
	const { labels = [], changeTab, tabWidth, tabWidth2 } = props;

	const handleChange = (event, newValue) => {
		setValue(newValue);
		changeTab && changeTab(newValue);
		setTabValue(newValue);
		tabValue && setTabValue(newValue);
	};

	const tabStyles = {
		indicator: {
			width: "33.33%",
		},
	};

	return (
		<>
			<Box>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						width: tabWidth ? "21%" : tabWidth2 ? "65%" : "34%",
					}}
				>
					{tabValue ? (
						<Tabs
							value={tabValue}
							onChange={handleChange}
							aria-label="basic tabs example"
							sx={tabStyles}
						>
							{labels?.map((label, index) => {
								return (
									<Tab
										label={label}
										{...a11yProps(index)}
										style={{
											textTransform: "capitalize",
											width: tabWidth ? "50%" : tabWidth2 ? "24%" : "30%",
										}}
									/>
								);
							})}
						</Tabs>
					) : (
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
							sx={tabStyles}
						>
							{labels?.map((label, index) => {
								return (
									<Tab
										label={label}
										{...a11yProps(index)}
										style={{
											textTransform: "capitalize",
											width: tabWidth ? "50%" : tabWidth2 ? "24%" : "30%",
										}}
									/>
								);
							})}
						</Tabs>
					)}
				</Box>
				<CustomTabPanel value={value} index={0}>
					{/* Pending Review */}
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					{/* Ignored */}
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					{/* Confirmed */}
				</CustomTabPanel>
			</Box>
		</>
	);
}
