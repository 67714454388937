import React, { useEffect, useState } from "react";
import "./SingleSystemMessage.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/topbar";
// import { TextField, Grid } from "@mui/material";
import { TextField, Grid, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SingleSystemMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useState(null);
    const [authToken, setAuthToken] = useState("");
    const [previousTab, setPreviousTab] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (token) {
            setAuthToken(token);
        }
    }, []);

    useEffect(() => {
        if (location.state && location.state.userData) {
            setUserData(location.state.userData);
        }
        if (location.state && location.state.previousTab) {
            setPreviousTab(location.state.previousTab);
        }
    }, [location.state]);

    const handleBackButtonClick = () => {
        const previousTab = localStorage.getItem("selectedTab") || 0;
        navigate("/system_message", { state: { previousTab } });
    };

    const messageStatusTitles = {
        "New Message": "New Messages",
        "Scheduled Messages": "Scheduled Messages",
        "Messages Sent": "Messages Sent",
        "Trigger Templates": "Trigger Templates",
    };

    const getMessageStatusTitle = (messageStatus) => {
        return messageStatusTitles[messageStatus] || "Unknown Status";
    };
    const formatDates = (value) => {
        const inputDate = new Date(value);
    
        const options = {
            year: "numeric", 
            month: "2-digit",
            day: "2-digit",
            hour12: true,
            timeZone: "America/Los_Angeles",
        };
        return inputDate.toLocaleDateString("en-US", options);
    }
    // const formatDate = (date) => {
    //     const [month, day, year] = date.split("/");
    //     return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // };
	// 	const formatDate = (date) => {
	// 		if (!date) {
	// 				return ""; // Or handle it according to your application's logic
	// 		}
	
	// 		const [month, day, year] = date.split("/");
	// 		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	// };
	const formatDate = (date) => {
    if (!date) {
        return "";
    }

    const parts = date.split("/");
    if (parts.length !== 3) {
        return ""; 
    }

    const [month, day, year] = parts;
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

// 	const handleUpdateSystemMessage = () => {
//     const user = JSON.parse(localStorage.getItem("userInfo"));
//     const userEmail = user.email;
//     const currentTime = new Date();
    
   
//     const requestData = {
//         subject: userData?.subject,
//         messageContent: userData?.messageContent,
//         recipient: userData?.recipient,
//         location: userEmail,
//         lastUpdated: currentTime.toISOString(),
//     };

//     // Include date and time only if the message status is "Scheduled Messages"
//     if (userData?.messageStatus === "Scheduled Messages") {
//         const formattedDate = formatDate(userData?.date);
//         requestData.date = formattedDate;
			
//         requestData.time = userData?.time;
//     }

//     // Make the API request
//     axios
//         .patch(
//             `${process.env.REACT_APP_BASE_URL}/api/v1/system_message/${userData._id}`,
//             requestData,
//             {
//                 headers: {
//                     Authorization: `${authToken}`,
//                 },
//             }
//         )
//         .then((response) => {
//             console.log("System message updated:", response.data);
//             toast.success("System message updated successfully");
//             navigate("/system_message");
//         })
//         .catch((error) => {
//             console.error("Error updating system message:", error);
//         });
// };
    const handleUpdateSystemMessage = () => {
        const formattedDate = formatDate(userData?.date);
        const user = JSON.parse(localStorage.getItem("userInfo"));
        const userEmail = user.email;
        const currentTime = new Date();
        axios
            .patch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/system_message/${userData._id}`,
                {
                    subject: userData?.subject,
                    messageContent: userData?.messageContent,
                    date: formattedDate,
                    time: userData?.time,
                    recipient: userData?.recipient,
                    location: userEmail,
                    lastUpdated: currentTime.toISOString(),
                },
                {
                    headers: {
                        Authorization: `${authToken}`,
                    },
                }
            )
            .then((response) => {
                console.log("System message updated:", response.data);
                toast.success("System message updated successfully");
                navigate("/system_message");
            })
            .catch((error) => {
                console.error("Error updating system message:", error);
            });
    };
    
    const handleDeleteSystemMessage = () => {
        axios
            .delete(
                `${process.env.REACT_APP_BASE_URL}/api/v1/system_message/${userData._id}`,
                {
                    headers: {
                        Authorization: `${authToken}`,
                    },
                }
            )
            .then((response) => {
                console.log("System message deleted:", response.data);
                toast.success("System message deleted successfully");
                navigate("/system_message");
            })
            .catch((error) => {
                console.error("Error deleting system message:", error);
            });
    };

    return (
        <div className="home-container">
            <Navbar />
            <div className="content-container">
                <Sidebar />
                <div className="main-content">
                    <div className="topbar-container">
                        <TopBar
                            leftIcon={
                                <Link to="/system_message" className="links">
                                    <ArrowBackIcon
                                        className="backIconStyling"
                                        onClick={handleBackButtonClick}
                                    />
                                </Link>
                            }
                            header={`${getMessageStatusTitle(userData?.messageStatus)} / ${userData?.subject}`}
                        />
                    </div>
                    <div className="message-container">
                        <Grid container spacing={3}>
                            {userData?.messageStatus !== "Messages Sent" && (
                                <>
                                    <Grid item lg={2} xs={3} md={3}>
                                        {/* <TextField
                                            className="to_field"
                                            fullWidth
                                            variant="outlined"
                                            label="To"
                                            value={userData?.recipient || ""}
                                            onChange={(e) =>
                                                setUserData({
                                                    ...userData,
                                                    recipient: e.target.value,
                                                })
                                            }
                                        /> */}
																				 <FormControl fullWidth variant="outlined">
                                            <InputLabel id="to-field-label">To</InputLabel>
                                            <Select
                                                labelId="to-field-label"
                                                id="to-field"
                                                label="To"
                                                value={userData?.recipient || ""}
                                                onChange={(e) =>
                                                    setUserData({
                                                        ...userData,
                                                        recipient: e.target.value,
                                                    })
                                                }
                                            >
                                                <MenuItem value="All App Users">All App Users</MenuItem>
                                               
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={1} xs={3} md={3}></Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        md={9}
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <button
                                            className="save_button"
                                            onClick={handleUpdateSystemMessage}
                                        >
                                            Save and Update
                                        </button>
                                        {userData?.messageStatus !== "Trigger Templates" && (
                                            <button
                                                className="delete_button"
                                                onClick={handleDeleteSystemMessage}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} xs={3} md={3}>
                                        <TextField
                                            className="subject_field"
                                            fullWidth
                                            variant="outlined"
                                            label="Subject"
                                            value={userData?.subject || ""}
                                            onChange={(e) =>
                                                setUserData({
                                                    ...userData,
                                                    subject: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    {userData?.messageStatus !== "Trigger Templates" && (
                                        <>
                                            <Grid item lg={5} xs={3} md={3}></Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    className="date_field"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Date"
                                                    value={formatDates(userData?.date || "")}
                                                    onChange={(e) =>
                                                        setUserData({ ...userData, date: e.target.value })
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    className="time_field"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Time"
                                                    value={userData?.time}
                                                    onChange={(e) =>
                                                        setUserData({ ...userData, time: e.target.value })
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <TextField
                                            className="message_field"
                                            fullWidth
                                            multiline
                                            minRows={10}
                                            maxRows={50}
                                            variant="outlined"
                                            label="Message"
                                            value={userData?.messageContent || ""}
                                            onChange={(e) =>
                                                setUserData({
                                                    ...userData,
                                                    messageContent: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                            {userData?.messageStatus === "Messages Sent" && (
                                <>
                                    <Grid item xs={3} md={3}>
                                        <TextField
                                            className="to_field"
                                            fullWidth
                                            variant="outlined"
                                            label="To"
                                            value={userData?.recipient || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        md={9}
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    ></Grid>
                                    <Grid item xs={3} md={3}>
                                        <TextField
                                            className="subject_field"
                                            fullWidth
                                            variant="outlined"
                                            label="Subject"
                                            value={userData?.subject || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item lg={5} xs={3} md={3}></Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            className="date_field"
                                            fullWidth
                                            variant="outlined"
                                            label="Date"
                                            value={formatDates(userData?.date || "")}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            className="time_field"
                                            fullWidth
                                            variant="outlined"
                                            label="Time"
                                            value={userData?.time ? `${userData.time} PST` : ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className="message_field"
                                            fullWidth
                                            multiline
                                            minRows={10}
                                            maxRows={50}
                                            variant="outlined"
                                            label="Message"
                                            value={userData?.messageContent || ""}
                                            disabled
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleSystemMessage;
