import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./topbar.scss";

const TopBar = ({
  header,
  showButton,
  showModifyButton,
  showCreateEventButton,
  onButtonClick,
  onModifyButtonClick,
  leftIcon,
  showAddEvent,
  onUpdateButtonClick,
  showSaveButton
}) => {
  return (
    <div className="top-bar">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className="top-bar-heading">
              <p>
                {leftIcon}
                {header}
              </p>
            </div>
          </Col>
          {showButton || showSaveButton || showAddEvent ? 
          null : <Col xs={1} sm={1} md={0} lg={2} xl={3} className="text-end colStyling"></Col>}
          {showModifyButton && (
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-end col2Styling">
              <div className="top-bar-buttons">
                {/* <button
                  className="top-bar-button top-bar-button-update"
                  onClick={onUpdateButtonClick}
                >
                  Update Password
                </button> */}
                <button
                  className="top-bar-button-modify top-bar-button-add"
                  onClick={onModifyButtonClick}
                >
                  Modify Question Bank
                </button>
              </div>
            </Col>
          )}
          {showCreateEventButton && (
            <Col xs={1} sm={1} md={1} lg={1} xl={1} className="text-end">
              <div className="top-bar-buttons">
                {/* <button
                  className="top-bar-button top-bar-button-update"
                  onClick={onUpdateButtonClick}
                >
                  Update Password
                </button> */}
                <button
                  className="top-bar-button top-bar-button-add"
                  onClick={onButtonClick}
                >
                  Create Event
                </button>
              </div>
            </Col>
          )}
          {showButton && (
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
              <div className="top-bar-buttons">
                {/* <button
                  className="top-bar-button top-bar-button-update"
                  onClick={onUpdateButtonClick}
                >
                  Update Password
                </button> */}
                <button
                  className="top-bar-button top-bar-button-add"
                  onClick={onButtonClick}
                >
                  Add Admin
                </button>
              </div>
            </Col>
          )}
          {showAddEvent && (
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
              <div className="top-bar-buttons">
                {/* <button
                  className="top-bar-button top-bar-button-update"
                  onClick={onUpdateButtonClick}
                >
                  Update Password
                </button> */}
                <button
                  className="top-bar-button top-bar-button-add"
                  onClick={onButtonClick}
                >
                  Add Event
                </button>
              </div>
            </Col>
          )}
          {showSaveButton && (
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
              <div className="top-bar-buttons">
                {/* <button
                  className="top-bar-button top-bar-button-update"
                  onClick={onUpdateButtonClick}
                >
                  Update Password
                </button> */}
                <button
                  className="top-bar-save-button top-bar-button-add"
                  onClick={onButtonClick}
                >
                  Save
                </button>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default TopBar;
